import React, { useEffect, useRef, useState, useCallback } from "react";
import * as tf from "@tensorflow/tfjs";
import PropTypes from "prop-types";
import Meyda from "meyda";

// Configuration (keep as before)
const sampleRate = 16000;
const modelPath = "/models/model.json";
const detectionThreshold = 0.8;
const FFT_SIZE = 512;
const HOP_LENGTH = 256;
const N_MFCC = 40;
const FEATURE_MEAN = -3.3359674282429186;
const FEATURE_STD = 46.60141388518999;
const WAKE_WORD_BUFFER_DURATION_S = 1.0; // How much audio to analyze

const AutoWakeWordLoader = ({
  onModelStatusChange,
  onWakeWordDetected,
  onStreamReady, // <<< New prop: Callback to pass the stream up
  enabled, // Controls *processing*, not stream acquisition
}) => {
  const modelRef = useRef(null);
  const [isModelReady, setIsModelReady] = useState(false); // <<< STATE to track readiness
  const audioContextRef = useRef(null);
  const microphoneSourceRef = useRef(null); // To hold the source node from the shared stream
  const analyserRef = useRef(null);
  const workletNodeRef = useRef(null);
  const streamRef = useRef(null); // Holds the persistent MediaStream
  const audioBufferRef = useRef(null);
  const audioBufferIndexRef = useRef(0);
  const meydaAnalyzerRef = useRef(null);
  const lastDetectionTimeRef = useRef(0);
  const isMountedRef = useRef(true); // Track mount status
  const mfccFramesRef = useRef([]); // <<< To store MFCC frames from Meyda callback

  // State and Ref for processing status
  const [isProcessing, setIsProcessing] = useState(false); // React state for triggering effects etc.
  const isProcessingRef = useRef(false); // Ref for synchronous checks inside handlers

  const detectionCooldownMs = 1000;

  // // DEBUG ===============================
  // useEffect(() => {
  //   console.log(
  //     ">>> AutoWakeWordLoader [Prop Effect]: Received onWakeWordDetected prop identity:",
  //     onWakeWordDetected
  //   );
  // }, [onWakeWordDetected]); // Run when the function prop changes
  // // END DEBUG ========================

  // Effect to keep the Ref synchronized with the State
  useEffect(() => {
    isProcessingRef.current = isProcessing;
  }, [isProcessing]);

  // --- Model Loading Effect ---
  useEffect(() => {
    isMountedRef.current = true;
    let localIsMounted = true;

    const loadModel = async () => {
      // console.log(">>> AutoWakeWordLoader: Model loading effect started.");
      try {
        setIsModelReady(false);
        if (onModelStatusChange) onModelStatusChange(false);
        console.log("Loading wake word model from:", modelPath);

        const loadedModel = await tf.loadLayersModel(modelPath);

        if (localIsMounted) {
          // console.log(
          //   "Model loaded. Input shape:",
          //   loadedModel.inputs[0].shape
          // );
          modelRef.current = loadedModel;
          setIsModelReady(true);
          if (onModelStatusChange) onModelStatusChange(true);
          console.log(
            "Wake word model loaded successfully (state set to true)."
          );
        } else {
          console.log("Component unmounted before model load finished.");
        }
      } catch (error) {
        console.error("Error loading wake word model:", error);
        if (localIsMounted) {
          setIsModelReady(false);
          if (onModelStatusChange) onModelStatusChange(false);
        }
      }
    };

    loadModel();

    // Cleanup function for the model loading effect
    return () => {
      // console.log(">>> AutoWakeWordLoader: Model loading effect cleanup.");
      localIsMounted = false;
      isMountedRef.current = false;
      // Full cleanup on unmount
      fullAudioCleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelPath]); // Only depends on modelPath

  // --- Full Audio Resource Cleanup ---
  const fullAudioCleanup = useCallback(() => {
    // console.log(">>> AutoWakeWordLoader: fullAudioCleanup called.");

    // Stop Meyda first
    if (meydaAnalyzerRef.current) {
      try {
        meydaAnalyzerRef.current.stop();
      } catch (e) {
        console.warn("Meyda stop error:", e);
      }
      meydaAnalyzerRef.current = null;
    }

    // Disconnect nodes
    if (workletNodeRef.current) {
      try {
        workletNodeRef.current.port.onmessage = null;
        workletNodeRef.current.disconnect();
      } catch (e) {}
      workletNodeRef.current = null;
    }
    if (analyserRef.current) {
      try {
        analyserRef.current.disconnect();
      } catch (e) {}
      analyserRef.current = null;
    }
    if (microphoneSourceRef.current) {
      try {
        microphoneSourceRef.current.disconnect();
      } catch (e) {}
      microphoneSourceRef.current = null;
    }

    // Close AudioContext
    if (audioContextRef.current && audioContextRef.current.state !== "closed") {
      audioContextRef.current
        .close()
        .catch((e) => console.error("AudioContext close error:", e));
    }
    audioContextRef.current = null;

    // Stop MediaStream Tracks
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => {
        if (track.readyState === "live") {
          track.stop();
        }
      });
      streamRef.current = null;
      if (onStreamReady) {
        onStreamReady(null);
      } // Notify parent stream is gone
    }

    // Update status ref and state
    isProcessingRef.current = false;
    setIsProcessing(false);

    audioBufferRef.current = null;
    audioBufferIndexRef.current = 0;
    console.log("Full Audio Cleanup finished.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onStreamReady]); // Add other dependencies if needed, though onStreamReady is key

  // --- Pause Audio Processing ---
  const pauseAudioProcessing = useCallback(() => {
    // console.log(">>> PAUSE called. isProcessingRef:", isProcessingRef.current); // <<< ADD LOG
    // Use ref for immediate check to prevent race conditions
    if (!isProcessingRef.current) {
      // console.log("Pause called but not processing (ref check).");
      return;
    }
    // console.log(">>> AutoWakeWordLoader: pauseAudioProcessing called.");

    if (meydaAnalyzerRef.current) {
      try {
        meydaAnalyzerRef.current.stop();
        console.log("Meyda stopped.");
      } catch (e) {
        console.warn("Meyda stop error during pause:", e);
      }
    }

    if (workletNodeRef.current) {
      try {
        workletNodeRef.current.port.onmessage = null;
        workletNodeRef.current.disconnect();
        console.log("Worklet node disconnected.");
      } catch (e) {}
    }
    if (analyserRef.current) {
      try {
        analyserRef.current.disconnect();
        console.log("Analyser node disconnected.");
      } catch (e) {}
    }
    // Do NOT disconnect microphoneSourceRef from context here

    // Update status ref and state
    isProcessingRef.current = false;
    setIsProcessing(false);

    console.log("Wake word audio processing paused.");
  }, []); // No dependencies needed if it only modifies refs/state

  // --- Setup / Resume Audio Processing ---
  const setupOrResumeAudioProcessing = useCallback(async () => {
    // Check using ref for most up-to-date status
    if (isProcessingRef.current || !isModelReady || !isMountedRef.current) {
      // console.log("Skipping setup/resume:", { isProcessing: isProcessingRef.current, isModelReady, isMounted: isMountedRef.current });
      return;
    }
    // console.log(
    //   ">>> AutoWakeWordLoader: Entering setupOrResumeAudioProcessing..."
    // );
    console.log("Setting up or resuming wake word audio processing...");

    try {
      // 1. Get MediaStream (only once)
      if (!streamRef.current) {
        console.log("Requesting microphone access...");
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { sampleRate: sampleRate, channelCount: 1 },
        });
        if (!isMountedRef.current) {
          stream.getTracks().forEach((track) => track.stop());
          return;
        }
        streamRef.current = stream;
        console.log("Microphone access granted.");
        if (onStreamReady) {
          // console.log(
          //   ">>> AutoWakeWordLoader: Calling onStreamReady with stream."
          // );
          onStreamReady(streamRef.current);
        }
      }

      // 2. Get/Create AudioContext
      if (
        !audioContextRef.current ||
        audioContextRef.current.state === "closed"
      ) {
        console.log("Creating or recreating AudioContext...");
        if (
          audioContextRef.current &&
          audioContextRef.current.state !== "closed"
        ) {
          await audioContextRef.current
            .close()
            .catch((e) => console.error("Error closing previous context:", e));
        }
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)({ sampleRate });
        // console.log(
        //   `AudioContext created/recreated. State: ${audioContextRef.current.state}, Sample Rate: ${audioContextRef.current.sampleRate}`
        // );
        microphoneSourceRef.current = null; // Reset source if context was recreated
      }
      if (audioContextRef.current.state === "suspended") {
        await audioContextRef.current.resume();
        console.log("AudioContext resumed.");
      }

      // 3. Create MediaStreamSource (if needed)
      if (
        !microphoneSourceRef.current &&
        streamRef.current &&
        audioContextRef.current
      ) {
        console.log("Creating MediaStreamSource...");
        microphoneSourceRef.current =
          audioContextRef.current.createMediaStreamSource(streamRef.current);
        console.log("MediaStreamSource created.");
      }

      // 4. Setup Nodes (Analyser, Worklet)
      if (!analyserRef.current && audioContextRef.current) {
        analyserRef.current = audioContextRef.current.createAnalyser();
        console.log("Analyser node created.");
      }
      if (!workletNodeRef.current && audioContextRef.current) {
        console.log("Setting up AudioWorklet...");
        const workletCode = `
                  let processCallCount = 0;
                  class AudioBufferProcessor extends AudioWorkletProcessor {
                    process(inputs, outputs, parameters) {
                      processCallCount++;
                      try {
                        const inputChannels = inputs[0]; // Get the array of input channels

                        // --- Input Validation (Check channels first) ---
                        if (!inputChannels || inputChannels.length === 0) {
                          // Log occasionally if input is missing
                          // if (processCallCount % 500 === 0) console.warn('[Worklet] Process Count:', processCallCount, ' - No input channels.');
                          return true; // Keep processor alive
                        }

                        // --- Define inputData HERE ---
                        const inputData = inputChannels[0]; // Get the Float32Array for the first channel

                        // --- Input Validation (Check data AFTER definition) ---
                        if (!inputData || !(inputData instanceof Float32Array)) {
                           // Log occasionally
                           // if (processCallCount % 500 === 0) console.warn('[Worklet] Process Count:', processCallCount, ' - Invalid data type for channel 0.');
                           return true; // Keep alive
                        }

                        const currentInputLength = inputData.length; // Now safe to use inputData
                        if (currentInputLength === 0) {
                           return true; // Keep alive, just no data this time
                        }

                        // Log occasionally to show activity
                        // if (processCallCount % 500 === 0) console.log('[Worklet] Process Count:', processCallCount, 'Input buffer size:', currentInputLength);

                        // --- Data Processing & Sending ---
                        const dataToSend = inputData.slice(); // Now safe to use inputData

                        if (!dataToSend || dataToSend.length === 0) {
                           console.warn('[Worklet] dataToSend is empty or invalid after slice.');
                           return true;
                        }

                        // console.log('[Worklet] Attempting to post message. Size:', dataToSend.length); // Optional log
                        this.port.postMessage({ audioData: dataToSend });

                      } catch (error) {
                        console.error('[Worklet] CRITICAL ERROR in process method! Count:', processCallCount, 'Error:', error.message, error.stack);
                        this.port.postMessage({ type: 'worklet_error', message: error.message });
                        return true; // Keep alive even on error for now
                      }
                      return true; // MUST return true
                    }
                  }
                  registerProcessor('audio-buffer-processor', AudioBufferProcessor);
              `;
        const blob = new Blob([workletCode], {
          type: "application/javascript",
        });
        const workletUrl = URL.createObjectURL(blob);
        try {
          await audioContextRef.current.audioWorklet.addModule(workletUrl);
          workletNodeRef.current = new AudioWorkletNode(
            audioContextRef.current,
            "audio-buffer-processor"
          );
          console.log("AudioWorklet node created and module added.");
        } catch (error) {
          console.error("Failed to add AudioWorklet module:", error);
        } finally {
          URL.revokeObjectURL(workletUrl);
        }
      }

      // 5. Connect Nodes
      if (
        microphoneSourceRef.current &&
        analyserRef.current &&
        workletNodeRef.current
      ) {
        console.log("Connecting audio nodes...");
        microphoneSourceRef.current.disconnect(); // Ensure clean state
        microphoneSourceRef.current.connect(analyserRef.current);
        analyserRef.current.connect(workletNodeRef.current);
        console.log("Audio nodes connected.");
      } else {
        console.warn(
          "Skipping node connection - one or more nodes are missing."
        );
        return;
      }

      // 6. Setup Audio Buffer
      if (!audioBufferRef.current) {
        const bufferSize = Math.round(sampleRate * WAKE_WORD_BUFFER_DURATION_S);
        audioBufferRef.current = new Float32Array(bufferSize);
        audioBufferIndexRef.current = 0;
        // console.log(`Audio buffer created (size: ${bufferSize}).`);
      }

      // 7. Setup Worklet Message Handler (using the isProcessingRef)
      if (workletNodeRef.current) {
        let messageHandlerCount = 0;
        workletNodeRef.current.port.onmessage = (event) => {
          messageHandlerCount++;
          try {
            if (!isProcessingRef.current || !enabled) {
              return;
            } // Check REF
            if (!event.data || !event.data.audioData) {
              return;
            }
            const input = event.data.audioData;
            const inputLen = input?.length || 0;
            if (inputLen === 0) return;

            // --- Raw Audio Buffering (Still needed to know WHEN to check MFCCs) ---
            if (!audioBufferRef.current) {
              return;
            } // Check raw audio buffer ref
            const buffer = audioBufferRef.current;
            const bufferLen = buffer.length;
            let currentIndex = audioBufferIndexRef.current;
            let newIndex;
            // (Use your previous working raw audio buffer logic here)
            if (currentIndex + inputLen >= bufferLen) {
              const startFromInput = Math.max(0, inputLen - bufferLen);
              const relevantInput = input.slice(startFromInput);
              buffer.set(relevantInput.slice(0, bufferLen));
              newIndex = bufferLen;
            } else {
              buffer.set(input, currentIndex);
              newIndex = currentIndex + inputLen;
            }
            audioBufferIndexRef.current = newIndex; // Update raw audio buffer index

            // --- Check if raw audio buffer is full, THEN call detectWakeWord ---
            if (newIndex >= bufferLen) {
              // console.log(
              //   `>>> [${messageHandlerCount}] Raw Audio Buffer full. Triggering detectWakeWord (which checks MFCC buffer).`
              // );
              detectWakeWord() // <<< Call WITHOUT buffer argument
                .catch((err) =>
                  console.error(`>>> Error executing detectWakeWord:`, err)
                );
              audioBufferIndexRef.current = 0; // Reset raw audio buffer index
            }
          } catch (handlerError) {
            console.error(
              `>>> [${messageHandlerCount}] CRITICAL ERROR inside onmessage handler!`,
              handlerError
            );
          }
        };
        // console.log("Worklet message handler attached.");
      }

      // 8. Setup/Start Meyda
      if (analyserRef.current && audioContextRef.current) {
        if (meydaAnalyzerRef.current) {
          // Stop previous instance if exists
          try {
            meydaAnalyzerRef.current.stop();
          } catch (e) {}
          meydaAnalyzerRef.current = null;
        }
        if (!meydaAnalyzerRef.current) {
          // Create new instance
          // console.log("Creating Meyda Analyzer with callback for MFCCs...");
          const targetFramesForModel = 63; // How many frames the model input needs

          meydaAnalyzerRef.current = Meyda.createMeydaAnalyzer({
            audioContext: audioContextRef.current,
            source: analyserRef.current,
            bufferSize: FFT_SIZE,
            hopSize: HOP_LENGTH, // Ensure hop size is considered by Meyda internally
            numberOfMFCCCoefficients: N_MFCC, // 40
            sampleRate: audioContextRef.current.sampleRate,
            featureExtractors: ["mfcc"],
            callback: (features) => {
              // This callback receives features object, e.g., { mfcc: [40 coefficients] }
              if (
                features &&
                features.mfcc &&
                features.mfcc.length === N_MFCC
              ) {
                // --- Buffer the valid MFCC frame ---
                const currentFrames = mfccFramesRef.current;
                currentFrames.push(features.mfcc);

                // Keep only the last N frames needed for the model
                if (currentFrames.length > targetFramesForModel) {
                  mfccFramesRef.current = currentFrames.slice(
                    currentFrames.length - targetFramesForModel
                  );
                } else {
                  mfccFramesRef.current = currentFrames;
                }
                // Log occasionally
                // if (mfccFramesRef.current.length === targetFramesForModel && Math.random() < 0.05) { // Log ~5% of the time when buffer is full
                //    console.log(`>>> Meyda Callback: MFCC buffer full (${mfccFramesRef.current.length} frames)`);
                // }
              } else {
                // Log if Meyda callback provides unexpected data
                console.warn(
                  ">>> Meyda Callback received invalid features:",
                  features
                );
              }
            }, // End callback
          }); // End createMeydaAnalyzer
        }
        try {
          meydaAnalyzerRef.current.start();
          // console.log("Meyda Analyzer started.");
        } catch (e) {
          console.warn("Meyda start error:", e);
        }
      } // End Meyda setup block

      // --- IMPORTANT: Update state AND ref at the end of successful setup ---
      isProcessingRef.current = true;
      setIsProcessing(true);
      console.log("Wake word audio processing setup/resume complete.");
    } catch (error) {
      console.error("Error setting up/resuming audio processing:", error);
      // Ensure ref/state are false on error
      isProcessingRef.current = false;
      setIsProcessing(false);
      // Attempt cleanup on error
      fullAudioCleanup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isModelReady,
    enabled,
    fullAudioCleanup,
    pauseAudioProcessing,
    onStreamReady,
  ]); // Add detectWakeWord if used inside

  // --- Effect to Control Processing based on 'enabled' prop ---
  useEffect(() => {
    // console.log(
    //   `>>> AutoWakeWordLoader: Audio Control Effect Run. enabled=${enabled}, isModelReady=${isModelReady}`
    // );
    if (enabled && isModelReady) {
      // Setup function checks isProcessingRef internally
      setupOrResumeAudioProcessing();
    } else if (!enabled) {
      // Use ref for immediate check if currently processing
      if (isProcessingRef.current) {
        pauseAudioProcessing();
      }
    }
  }, [
    enabled,
    isModelReady,
    setupOrResumeAudioProcessing,
    pauseAudioProcessing,
  ]); // Dependencies are correct

  // --- Feature Extraction (Example - ensure it's stable) ---
  // --- Feature Extraction (Add detailed logging) ---
  const extractFeatures = useCallback((buffer) => {
    // console.log(
    //   `>>> extractFeatures: Called with buffer length ${buffer?.length}`
    // ); // Log entry

    // --- Check buffer integrity (optional but helpful) ---
    let hasNaN = false;
    let sum = 0;
    for (let i = 0; i < buffer.length; i++) {
      if (isNaN(buffer[i])) {
        hasNaN = true;
        break;
      }
      sum += Math.abs(buffer[i]);
    }
    if (hasNaN) {
      console.error(">>> extractFeatures: Input buffer contains NaN!");
      return null; // Cannot process NaN
    }
    if (sum === 0) {
      console.warn(">>> extractFeatures: Input buffer is all zeros.");
      // Decide if you should return null or try processing anyway
      // return null; // Maybe return null if buffer is silent?
    }
    // --- End Check ---

    const features = []; // Initialize empty features array
    const frameSize = FFT_SIZE; // 512
    const hopSize = HOP_LENGTH; // 256
    let frameCount = 0; // Count processed frames
    let errorCount = 0; // Count errors
    let invalidMfccCount = 0; // Count invalid returns

    // console.log(
    //   `>>> extractFeatures: Looping through buffer. FrameSize=${frameSize}, HopSize=${hopSize}`
    // );

    // Extract base MFCCs frame by frame using Meyda's static extract
    for (let i = 0; i <= buffer.length - frameSize; i += hopSize) {
      frameCount++;
      const frame = buffer.slice(i, i + frameSize);

      try {
        // --- Log frame details occasionally ---
        // if (frameCount % 50 === 0) { // Log every 50 frames
        //    console.log(`>>> extractFeatures: Processing frame ${frameCount}, Slice ${i} to ${i + frameSize}`);
        //    // Log first few values of the frame to check content
        //    // console.log(`>>> Frame Sample [${frameCount}]:`, frame.slice(0, 5));
        // }

        const mfccs = Meyda.extract("mfcc", frame, {
          numberOfMFCCCoefficients: N_MFCC,
        });

        // --- Log what Meyda returns ---
        if (frameCount === 1 || frameCount % 50 === 0) {
          // Log first frame and periodically
          // console.log(
          //   `>>> extractFeatures: Meyda.extract returned (frame ${frameCount}):`,
          //   mfccs
          // );
        }

        // --- Check the result carefully ---
        if (mfccs && Array.isArray(mfccs) && mfccs.length === N_MFCC) {
          // Check for NaN within the MFCCs themselves
          if (mfccs.some(isNaN)) {
            console.warn(
              `>>> extractFeatures: Frame ${frameCount} MFCCs contain NaN! Skipping frame.`
            );
            invalidMfccCount++;
          } else {
            features.push(mfccs); // Only push valid arrays of the correct length without NaN
          }
        } else {
          // Log why it failed
          // console.warn(`>>> extractFeatures: Invalid MFCCs for frame ${frameCount}. Length: ${mfccs?.length}, IsArray: ${Array.isArray(mfccs)}. Skipping frame.`);
          invalidMfccCount++;
        }
      } catch (error) {
        // --- Log any error during extraction ---
        errorCount++;
        if (errorCount <= 5) {
          // Log first few errors
          console.error(
            `>>> extractFeatures: Error extracting MFCCs on frame ${frameCount}:`,
            error.message,
            error
          );
        } else if (errorCount === 6) {
          console.error(
            ">>> extractFeatures: Too many MFCC errors, suppressing further logs."
          );
        }
      }
    } // End loop

    // console.log(
    //   `>>> extractFeatures: Loop finished. Frames processed: ${frameCount}, Features added: ${features.length}, Errors: ${errorCount}, Invalid MFCCs: ${invalidMfccCount}`
    // );

    // --- Return null ONLY if no valid features were added ---
    if (features.length === 0) {
      console.error(
        ">>> extractFeatures: No valid features were extracted after processing all frames."
      );
      return null;
    }

    // --- Proceed with feature post-processing (trim/pad, deltas, etc.) ---
    // console.log(
    //   `>>> extractFeatures: Proceeding with post-processing for ${features.length} feature vectors.`
    // );
    const targetFrames = 63; // From model input shape [null, 120, 63, 1]
    // ... (Rest of the existing post-processing logic: trim/pad, calculate deltas, combine, flatten) ...
    // ... Ensure this part is also robust ...

    // Example placeholder for the rest (use your actual logic)
    let processedFeatures = features;
    if (features.length > targetFrames) {
      processedFeatures = features.slice(features.length - targetFrames);
    } else if (features.length < targetFrames) {
      /* ... padding ... */
    }
    const baseMfccs = []; // = Convert processedFeatures to coefficient-major
    const deltaMfccs = []; // = Calculate deltas
    const deltaDeltaMfccs = []; // = Calculate delta-deltas
    const combinedFeatures = [...baseMfccs, ...deltaMfccs, ...deltaDeltaMfccs];
    const flattenedData = new Float32Array(120 * targetFrames); // = Flatten combinedFeatures

    // console.log(
    //   `>>> extractFeatures: Post-processing complete. Returning flattened data length: ${
    //     flattenedData?.length || "N/A"
    //   }`
    // );
    return flattenedData;
  }, []); // Add N_MFCC, FFT_SIZE, HOP_LENGTH to dependencies if they could change

  // --- Wake Word Detection Logic ---
  const detectWakeWord = useCallback(async () => {
    // <<< REMOVED buffer argument
    if (!isProcessingRef.current) {
      return;
    }
    // console.log(`>>> detectWakeWord called.`); // Simplified entry log

    if (!modelRef.current || !enabled) {
      /* ... check ... */ return;
    }
    const now = Date.now();
    if (now - lastDetectionTimeRef.current < detectionCooldownMs) {
      return;
    }

    try {
      // --- Get MFCC Frames from Ref ---
      const currentMfccFrames = mfccFramesRef.current;
      const targetFramesForModel = 63; // Frames needed

      // --- Check if enough frames are buffered ---
      if (
        !currentMfccFrames ||
        currentMfccFrames.length < targetFramesForModel
      ) {
        // console.log(`>>> detectWakeWord: Not enough MFCC frames buffered (${currentMfccFrames?.length}/${targetFramesForModel}). Skipping.`);
        return;
      }

      // console.log(
      //   `>>> detectWakeWord: Processing ${currentMfccFrames.length} buffered MFCC frames.`
      // );

      // --- Process the collected MFCC frames ---
      // (mfccFeatures here ARE the frames [targetFrames, N_MFCC])
      const mfccFeatures = currentMfccFrames; // Use the collected frames directly

      // Process features for model input (This logic stays mostly the same, using mfccFeatures)
      const inputTensor = tf.tidy(() => {
        const numFrames = targetFramesForModel; // Should be 63
        const numCoeffs = N_MFCC; // Should be 40

        // Initialize arrays for base MFCCs in coefficient-first format
        const baseMfccs = Array(numCoeffs)
          .fill()
          .map(() => Array(numFrames).fill(0));

        // Fill the base MFCCs (coefficient-first format)
        // mfccFeatures is [frame, coeff]
        for (let t = 0; t < numFrames; t++) {
          for (let c = 0; c < numCoeffs; c++) {
            baseMfccs[c][t] = mfccFeatures[t]?.[c] || 0.0; // Get value from buffered frame
          }
        }

        // --- Calculate Deltas (Ensure calculateDeltas is defined/correct) ---
        const calculateDeltas = (coeffs) => {
          // Implement or verify your delta calculation logic here
          // Example simplified logic (use your actual robust one)
          const numCoeffs = coeffs.length;
          const numFrames = coeffs[0]?.length || 0;
          if (numFrames === 0) return [];
          const deltas = Array(numCoeffs)
            .fill()
            .map(() => Array(numFrames).fill(0.0));
          for (let c = 0; c < numCoeffs; c++) {
            for (let t = 1; t < numFrames - 1; t++) {
              deltas[c][t] = (coeffs[c][t + 1] - coeffs[c][t - 1]) / 2;
            }
            if (numFrames > 0)
              deltas[c][0] = (coeffs[c][1] || 0.0) - (coeffs[c][0] || 0.0);
            if (numFrames > 1)
              deltas[c][numFrames - 1] =
                (coeffs[c][numFrames - 1] || 0.0) -
                (coeffs[c][numFrames - 2] || 0.0);
          }
          return deltas;
        };
        const deltaMfccs = calculateDeltas(baseMfccs);
        const deltaDeltaMfccs = calculateDeltas(deltaMfccs);

        // Combine features (120 rows: 40 base, 40 delta, 40 delta-delta)
        const combinedFeaturesList = [
          ...baseMfccs,
          ...deltaMfccs,
          ...deltaDeltaMfccs,
        ];

        // Create the final data array (flattened, 120x63)
        const data = new Float32Array(120 * numFrames);
        for (let featureIdx = 0; featureIdx < 120; featureIdx++) {
          for (let frameIdx = 0; frameIdx < numFrames; frameIdx++) {
            data[featureIdx * numFrames + frameIdx] =
              combinedFeaturesList[featureIdx]?.[frameIdx] || 0.0;
          }
        }

        const reshapedData = tf.tensor(data, [120, numFrames]); // Should be [120, 63]
        const expandedData = reshapedData.expandDims(0).expandDims(-1); // [1, 120, 63, 1]
        return expandedData.sub(FEATURE_MEAN).div(FEATURE_STD); // Apply normalization
      });

      // --- Run Inference ---
      // console.log(">>> detectWakeWord: Calling model.predict...");
      const prediction = modelRef.current.predict(inputTensor);
      const score = await prediction.data();
      tf.dispose([inputTensor, prediction]);
      // console.log(">>> detectWakeWord: Prediction complete.");

      const currentScore = score[0];
      if (currentScore > 0.7) {
        console.log(`>>> detectWakeWord: Raw score: ${currentScore}`);
      }

      // --- Handle Score ---
      if (currentScore > detectionThreshold) {
        // Use actual threshold
        console.log("Wake word detected! Confidence:", currentScore);
        // ... rest of detection logic (call parent, update state/ref) ...
        lastDetectionTimeRef.current = now;
        onWakeWordDetected(currentScore);
        isProcessingRef.current = false;
        setIsProcessing(false);
        mfccFramesRef.current = []; // Clear buffer after detection
      }
      // Optionally clear buffer even if no detection, or keep overlap?
      // mfccFramesRef.current = mfccFramesRef.current.slice(overlapAmount); // Example overlap
    } catch (error) {
      console.error(">>> CRITICAL ERROR inside detectWakeWord:", error);
      isProcessingRef.current = false;
      setIsProcessing(false);
      mfccFramesRef.current = []; // Clear buffer on error
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enabled,
    onWakeWordDetected,
    detectionThreshold /* + any other dependencies */,
  ]);

  // This component doesn't render anything visible
  return null;
};

AutoWakeWordLoader.propTypes = {
  onModelStatusChange: PropTypes.func,
  onWakeWordDetected: PropTypes.func.isRequired,
  onStreamReady: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
};

// --- Default Props ---
AutoWakeWordLoader.defaultProps = {
  enabled: true,
  onModelStatusChange: () => {}, // Provide no-op default
};

export default React.memo(AutoWakeWordLoader); // Memoize if props don't change often
