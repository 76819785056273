import React, { useState, useRef, useEffect, useCallback } from "react"; // Added React and useCallback
import FeatherIcon from "./FeatherIcons";
import { formatDate } from "../utils/dateUtils";
import { useTheme } from "../components/ThemeProvider";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faGear,
  faUser,
  faChevronUp,
  faQuestionCircle,
  // faRobot, // Removed if not used
  // faMicrophone, // Replaced by lucide icons
  // faMicrophoneSlash, // Replaced by lucide icons
} from "@fortawesome/free-solid-svg-icons";
import {
  ListChecks,
  NotebookText,
  // BotMessageSquare, // Removed if not used
  Clock,
  Mic,
  MicOff,
  Sidebar as SidebarIcon, // Use lucide sidebar
  Calendar as CalendarIcon,
  Search as SearchIcon,
} from "lucide-react";
import AssistantWrapper from "./Tiptap/extensions/AssistantWrapper";
import AutoWakeWordLoader from "./AutoWakeWordLoader";

const AppFooter = ({
  toggleSidebar,
  toggleCalendarVisibility,
  toggleSearchVisibility,
  selectedDate,
  hasUnsavedChanges,
  isLoading,
  handleDateClick,
  reloadContent,
  isSidebarOpen,
  sidebarWidth,
  showSidebarToggle = true,
  showCalendar = true,
  showSearch = true,
  showCompletedTasks = true,
  showUserDropdown = true,
  showJournal = false,
  showassistant = false, // Manual Assistant visibility trigger
  showTimeLine = false,
}) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null);
  const assistantWrapperRef = useRef(null); // Ref to AssistantWrapper instance
  const assistantRef = useRef(null); // Ref to the actual Assistant component inside Wrapper

  // --- State Management ---
  const [wakeWordModelLoaded, setWakeWordModelLoaded] = useState(false);
  // 'wakeWordEnabled' controls if AutoWakeWordLoader *processes* audio
  const [wakeWordEnabled, setWakeWordEnabled] = useState(true);
  // Holds the shared MediaStream from AutoWakeWordLoader
  const [sharedMediaStream, setSharedMediaStream] = useState(null);
  // Flag to trigger Assistant listening sequence
  const [assistantShouldListen, setAssistantShouldListen] = useState(false);
  // Controls visibility of the Assistant UI (TaskPlexer)
  const [showTaskPlexer, setShowTaskPlexer] = useState(false);
  // Store stream in an object wrapper
  const [streamInfo, setStreamInfo] = useState({
    stream: null,
    timestamp: null,
  });
  const currentStreamRef = useRef(null);

  // --- Theme & Class Names (Unchanged) ---
  const navbarClass =
    theme === "light"
      ? "bg-light"
      : theme === "dark"
      ? "bg-dark"
      : "bg-purple-900";
  const textClass = theme === "light" ? "text-dark" : "text-light";
  const navItems = [
    { name: "Settings", path: "/settings", icon: faGear },
    { name: "Account", path: "/account", icon: faUser },
    { name: "Support", path: "/support", icon: faQuestionCircle },
  ];
  const handleLogout = async () => {
    await logout();
    navigate("/login", { state: { fromLogout: true } });
  };

  const toggleDropdown = () => {
    if (!dropdownOpen) {
      setDropdownOpen(true);
      setTimeout(() => setDropdownVisible(true), 50);
    } else {
      setDropdownVisible(false);
      setTimeout(() => setDropdownOpen(false), 300);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Add a check to ignore clicks on the microphone button or its container
      const micButtonEl = document.querySelector(
        '[aria-label*="Wake Word Detection"]'
      );
      if (
        micButtonEl &&
        (micButtonEl === event.target || micButtonEl.contains(event.target))
      ) {
        return; // Skip processing for microphone button clicks
      }

      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !toggleRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
        setTimeout(() => setDropdownOpen(false), 300);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // --- Callback passed to AutoWakeWordLoader ---
  const handleStreamReady = useCallback((stream) => {
    // console.log(">>> AppFooter: handleStreamReady called.");
    if (stream) {
      // console.log(">>> AppFooter: Received shared MediaStream:", stream);
      // Update the ref immediately for direct access
      currentStreamRef.current = stream;
      // Update the state object for reactive components
      setStreamInfo({ stream: stream, timestamp: Date.now() });
      // console.log(
      //   ">>> AppFooter: Called setStreamInfo with new stream object."
      // );
    } else {
      console.log(">>> AppFooter: Shared MediaStream received as null.");
      currentStreamRef.current = null;
      setStreamInfo({ stream: null, timestamp: Date.now() });
      // console.log(
      //   ">>> AppFooter: Called setStreamInfo with null stream object."
      // );
    }
  }, []);

  // --- Callback passed to AutoWakeWordLoader ---
  const handleWakeWordDetected = useCallback(
    (confidence) => {
      console.log(
        `AppFooter: handleWakeWordDetected called (Confidence: ${confidence.toFixed(
          4
        )}).`
      );

      // Access the stream directly from the ref
      const currentStream = currentStreamRef.current;
      // console.log(
      //   `>>> AppFooter [Handler Executing]: Checking currentStream:`,
      //   currentStream
      // );

      if (!wakeWordEnabled) {
        console.log(
          "AppFooter: Wake word detected, but processing is disabled. Ignoring."
        );
        return;
      }

      // Check the stream from the ref
      if (!currentStream) {
        console.error(
          "AppFooter: Wake word detected, but currentStream is missing! Cannot start assistant."
        );
        return;
      }

      console.log(
        "AppFooter: Wake word detected (Checks passed). Preparing Assistant."
      );
      setShowTaskPlexer(true);
      setAssistantShouldListen(true);
      setWakeWordEnabled(false);
      console.log("AppFooter: Wake word processing disabled (paused).");
    },
    [wakeWordEnabled] // No need for streamInfo in dependencies
  );

  // --- Callback passed to AssistantWrapper/Assistant ---
  const handleMicrophoneReleased = useCallback(() => {
    console.log("AppFooter: Microphone control released by Assistant.");

    // 1. Re-enable wake word *processing*
    //    (AutoWakeWordLoader's useEffect will handle resuming)
    setWakeWordEnabled(true);
    console.log("AppFooter: Wake word processing enabled (resumed).");

    // 2. Optionally hide TaskPlexer if desired after assistant finishes
    // setShowTaskPlexer(false); // Or keep it visible
  }, []); // No dependencies needed

  // --- Effect to Start Assistant Listening (Modified for streamInfo state) ---
  useEffect(() => {
    // Get the current stream from the state object
    const currentStream = streamInfo.stream;

    // Log status including check based on the derived stream
    // console.log(">>> AppFooter: Assistant startListening Effect triggered.", {
    //   assistantShouldListen,
    //   hasStream: !!currentStream, // Check based on stream from streamInfo
    //   hasAssistantRef: !!assistantRef.current,
    // });

    // Only proceed if:
    // 1. The signal (assistantShouldListen) is true
    // 2. The currentStream (derived from streamInfo) exists and is active
    // 3. The Assistant component's ref is available
    if (
      assistantShouldListen &&
      currentStream &&
      currentStream.active &&
      assistantRef.current
    ) {
      // console.log(
      //   ">>> AppFooter: Calling assistantRef.current.startListening with stream:",
      //   currentStream // Pass the stream obtained from streamInfo
      // );
      try {
        // *** CRITICAL: Ensure assistantRef.current.startListening exists ***
        // *** and accepts a MediaStream argument. ***
        assistantRef.current.startListening(currentStream); // Pass the correct stream variable
        // Reset the signal immediately after calling startListening
        setAssistantShouldListen(false);
      } catch (error) {
        console.error(
          "AppFooter: Error calling Assistant's startListening:",
          error
        );
        // Handle error: maybe hide TaskPlexer, re-enable wake word?
        setAssistantShouldListen(false); // Still reset signal
        setShowTaskPlexer(false);
        setWakeWordEnabled(true); // Try to recover
      }
    } else if (
      // Handle cases where prerequisites are missing when signal is true
      assistantShouldListen &&
      (!currentStream || !currentStream.active || !assistantRef.current)
    ) {
      // Log condition if signal is true but prerequisites are missing
      console.warn(
        "AppFooter: assistantShouldListen is true, but stream is missing/inactive or assistant ref is missing.",
        {
          hasStream: !!currentStream,
          isStreamActive: !!currentStream?.active, // Add active check info
          hasAssistantRef: !!assistantRef.current,
        }
      );
      // Reset the signal to prevent repeated checks
      setAssistantShouldListen(false);
      // Maybe show error state or attempt recovery
      setShowTaskPlexer(false);
    }
    // The dependencies should now include the entire streamInfo object and assistantShouldListen
    // We read streamInfo inside the effect, so we depend on it.
  }, [assistantShouldListen, streamInfo]); // <<< Updated Dependencies: Depend on streamInfo object

  // --- UI Elements ---
  const getCalendarButtonContent = () => {
    let iconColor, textColor;
    if (isLoading) {
      iconColor = textColor = "text-warning";
    } else if (!hasUnsavedChanges) {
      iconColor = textColor = "text-success";
    } else {
      iconColor = textColor = "text-secondary";
    }

    return (
      <>
        <FeatherIcon
          icon="calendar"
          width="15"
          height="15"
          className={iconColor}
        />
        <span className={`ms-2 ${textColor}`} style={{ fontSize: "0.8rem" }}>
          {formatDate(selectedDate || new Date())}
        </span>
      </>
    );
  };

  // Log the identity of the memoized callback whenever AppFooter re-renders
  // console.log(
  //   ">>> AppFooter [Render]: handleWakeWordDetected identity:",
  //   handleWakeWordDetected
  // );

  // Toggle button now ONLY controls the 'wakeWordEnabled' state
  const toggleWakeWordProcessing = (event) => {
    event.stopPropagation();
    const newState = !wakeWordEnabled;
    setWakeWordEnabled(newState);
    console.log(
      `AppFooter: Wake word processing manually ${
        newState ? "enabled" : "disabled"
      }.`
    );
  };

  // Get the ref to the actual Assistant component instance from AssistantWrapper
  // This assumes AssistantWrapper uses forwardRef and useImperativeHandle
  // or simply passes the ref down.
  const handleAssistantRef = useCallback((ref) => {
    assistantRef.current = ref;
    // console.log("AppFooter: Assistant component ref obtained.", ref);
  }, []);

  return (
    <>
      {/* Always render AutoWakeWordLoader, control its processing via 'enabled' prop */}
      <AutoWakeWordLoader
        onModelStatusChange={setWakeWordModelLoaded}
        onWakeWordDetected={handleWakeWordDetected}
        onStreamReady={handleStreamReady} // Pass the callback
        enabled={wakeWordEnabled} // Pass the processing control state
      />

      <footer className="w-100 bg-primary-subtle">
        <div
          className="container-fluid d-flex justify-content-between align-items-center py-1"
          style={{ minHeight: "40px" }}
        >
          {" "}
          {/* Added padding/min-height */}
          {/* Left Controls */}
          <div className="d-flex align-items-center">
            {showSidebarToggle && (
              <button
                className="btn btn-outline-secondary me-1 d-flex align-items-center border-0 p-1" /* Adjusted padding */
                onClick={toggleSidebar}
                style={{ height: "24px" }} /* Adjusted height */
                aria-label="Toggle Sidebar"
              >
                <SidebarIcon size={16} /> {/* Use Lucide icon */}
              </button>
            )}

            {showCalendar && (
              <button
                className="btn d-flex align-items-center border-0 me-1 p-1" /* Adjusted padding */
                onClick={toggleCalendarVisibility}
                style={{ height: "24px" }} /* Adjusted height */
                aria-label="Toggle Calendar Visibility"
              >
                {/* Consider using Lucide Calendar icon consistently */}
                <CalendarIcon
                  size={16}
                  className={
                    isLoading
                      ? "text-warning"
                      : !hasUnsavedChanges
                      ? "text-success"
                      : "text-secondary"
                  }
                />
                <span
                  className={`ms-1 ${
                    isLoading
                      ? "text-warning"
                      : !hasUnsavedChanges
                      ? "text-success"
                      : "text-secondary"
                  }`}
                  style={{ fontSize: "0.8rem" }}
                >
                  {formatDate(selectedDate || new Date())}
                </span>
              </button>
            )}

            {showSearch && (
              <button
                className="btn btn-outline-secondary d-flex align-items-center border-0 me-1 p-1" /* Adjusted padding */
                onClick={toggleSearchVisibility}
                style={{ height: "24px" }} /* Adjusted height */
                aria-label="Toggle Search Visibility"
              >
                <SearchIcon size={16} /> {/* Use Lucide icon */}
              </button>
            )}

            {/* Microphone Toggle Button - Controls wake word *processing* */}
            {wakeWordModelLoaded && (
              <button
                className={`btn btn-outline-${
                  wakeWordEnabled ? "success" : "danger"
                } d-flex align-items-center border-0 me-1 p-1`} /* Adjusted padding */
                onClick={toggleWakeWordProcessing}
                style={{ height: "24px" }} /* Adjusted height */
                aria-label={
                  wakeWordEnabled
                    ? "Disable Wake Word Processing"
                    : "Enable Wake Word Processing"
                }
                title={
                  wakeWordEnabled
                    ? "Disable Wake Word Processing"
                    : "Enable Wake Word Processing"
                }
              >
                {wakeWordEnabled ? <Mic size={16} /> : <MicOff size={16} />}
              </button>
            )}
            {/* Other Links (Done, Journal, Timeline) */}
            {showCompletedTasks && (
              <Link
                to="/CompletedTasksViewer"
                className="btn btn-outline-secondary d-flex align-items-center border-0 p-1 me-1"
                style={{ height: "24px" }}
              >
                <ListChecks size={16} />
              </Link>
            )}
            {showJournal && (
              <Link
                to="/journal"
                className="btn btn-outline-secondary d-flex align-items-center border-0 p-1 me-1"
                style={{ height: "24px" }}
              >
                <NotebookText size={16} />
              </Link>
            )}
            {showTimeLine && (
              <Link
                to="/timeline"
                className="btn btn-outline-secondary d-flex align-items-center border-0 p-1"
                style={{ height: "24px" }}
              >
                <Clock size={16} />
              </Link>
            )}

            {/* Assistant Wrapper - Render conditionally */}
            {/* Pass shared stream and ref callback */}
            {(showassistant || showTaskPlexer) && (
              <AssistantWrapper
                // Instead of ref={assistantWrapperRef}, pass a callback to get the inner Assistant's ref
                forwardedAssistantRef={handleAssistantRef}
                sharedMediaStream={streamInfo.stream} // <<< Pass the stream from the state object
                handleDateClick={handleDateClick}
                reloadContent={reloadContent}
                isSidebarOpen={isSidebarOpen}
                sidebarWidth={sidebarWidth}
                showTaskPlexer={showTaskPlexer}
                setShowTaskPlexer={setShowTaskPlexer}
                onMicrophoneReleased={handleMicrophoneReleased} // Pass release callback
              />
            )}
          </div>
          {/* User Dropdown (Right) */}
          {user && showUserDropdown && (
            <div className="navbar-nav">
              <div className="nav-item dropdown">
                <span
                  ref={toggleRef}
                  className={`nav-link ${textClass}`}
                  onClick={toggleDropdown}
                  style={{ cursor: "pointer" }}
                  aria-haspopup="true"
                  aria-expanded={dropdownOpen}
                >
                  {user.username}
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    className={`ms-2 transition-transform ${
                      dropdownOpen ? "rotate-180" : ""
                    }`}
                  />
                </span>
                {dropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className={`dropdown-menu ${dropdownVisible ? "show" : ""}`}
                    style={{
                      position: "absolute",
                      top: "auto",
                      bottom: "100%",
                      right: 0,
                      left: "auto",
                      transform: `translateY(${
                        dropdownVisible ? "0" : "10px"
                      })`,
                      opacity: dropdownVisible ? 1 : 0,
                      transition: "opacity 0.3s ease, transform 0.3s ease",
                      display: "block",
                      minWidth: "200px",
                      zIndex: 1000,
                      boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)",
                      borderRadius: "4px",
                      backgroundColor: theme === "light" ? "#fff" : "#343a40",
                    }}
                    aria-label="User Menu"
                  >
                    {navItems.map((item) => (
                      <Link
                        key={item.name}
                        className="dropdown-item"
                        to={item.path}
                        onClick={() => setDropdownOpen(false)}
                      >
                        <FontAwesomeIcon icon={item.icon} className="me-2" />
                        {item.name}
                      </Link>
                    ))}
                    <div className="dropdown-divider"></div>
                    <button className="dropdown-item" onClick={handleLogout}>
                      <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                      Logout
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </footer>
    </>
  );
};

export default AppFooter;
