//NEW_JournalApiService.js
import journalApi from "../config/JournalApiConfig";
import { formatDate } from "../utils/dateUtils";


const NEW_JournalApiService = {

  // refreshToken: async () => {
  //   try {
  //     const response = await journalApi.post('/api/auth/refresh');
  //     console.log("refreshToken: ", response);
  //     const { access_token } = response.data;
  //     adminApiConfig.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
  //     return access_token;
  //   } catch (error) {
  //     throw error;
  //   }
  // },

  /**
   * Deletes a user with the given ID.
   * @param {number} userId - The ID of the user to delete.
   * @param {string} adminUsername - The username of the admin performing the deletion.
   * @param {Object} options - Additional options for deletion.
   * @param {boolean} options.hardDelete - Whether to perform a hard delete.
   * @returns {Promise<void>}
   * @throws {Error} If the deletion fails or if the caller is not authorized.
   */
  deleteUser: async (userId, adminUsername, options = {}) => {
    try {
      await adminApiConfig.delete(`/api/users/${userId}`, {
        params: {
          hard_delete: options.hardDelete,
          admin_username: adminUsername
        }
      });
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 404:
            throw new Error('User not found or already deleted');
          case 400:
            throw new Error('Cannot delete your own account');
          case 403:
            throw new Error('Not authorized to delete users');
          default:
            throw new Error(
              error.response.data?.detail ||
              'An error occurred while deleting the user'
            );
        }
      }
      throw error;
    }
  },

  /**
   * Retrieves a list of all users and their current disk quotas.
   * @returns {Promise<Object>} A promise that resolves to an object with the following structure:
   *   {
   *     users: Array<{
   *       username: string,
   *       max_disk_usage: number | null // in bytes, null if no quota set
   *     }>
   *   }
   */
  listUsers: async (username) => {
    const response = await journalApi.get(`/users/${username}`);
    return response.data;
  },

  /**
   * Checks disk usage for a specific user or all users.
   * @param {string|null} username - The username to check, or null to check all users.
   * @param {string} callerrname - The username of the caller (should be 'admin').
   * @returns {Promise<Object>} A promise that resolves to an object with the following structure:
   *   {
   *     results: Array<{
   *       username: string,
   *       current_usage: number,
   *       max_usage: number,
   *       is_exceeded: boolean
   *     }>
   *   }
   * @throws {Error} If the check fails or if the caller is not authorized.
   */
  checkDiskUsage: async (username, callerrname) => {
    try {
      const response = await journalApi.post('/check_disk_usage',
        { username },
        {
          headers: {
            'callername': callerrname
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          throw new Error('You are not authorized to check disk usage.');
        }
      }
      throw new Error('Failed to check disk usage. Please try again later.');
    }
  },

  /**
   * Updates the disk quota for a specific user.
   * @param {string} username - The username of the user whose quota is being updated.
   * @param {number} maxDiskUsage - The new maximum disk usage allowed for the user, in bytes.
   * @param {string} callerrname - The username of the caller (should be 'admin').
   * @returns {Promise<Object>} A promise that resolves to an object with the following structure:
   *   {
   *     message: string // A success message
   *   }
   * @throws {Error} If the update fails or if the caller is not authorized.
   */
  updateQuota: async (username, maxDiskUsage, callerrname) => {
    try {
      const response = await journalApi.post('/update_quota',
        { username, max_disk_usage: maxDiskUsage },
        {
          headers: {
            'callername': callerrname
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 403) {
          throw new Error('You are not authorized to update anything.');
        }
      }
      // For other types of errors, throw a generic error message
      throw new Error('Failed to update user quota. Please try again later.');
    }
  },

  /**
   * Triggers a background disk usage check for all users.
   * @param {string} callerrname - The username of the caller.
   * @returns {Promise<Object>} A promise that resolves to an object with the following structure:
   *   {
   *     message: string // A success message
   *   }
   * @throws {Error} If the trigger fails or if the caller is not authorized.
   */
  triggerBackgroundCheck: async (callerrname) => {
    try {
      const response = await journalApi.post('/trigger_background_check',
        {},  // Empty body
        {
          headers: {
            'callername': callerrname
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          throw new Error('You are not authorized to trigger background checks.');
        } else if (error.response.status === 400) {
          throw new Error('Not now baby!');
        }
      }
      throw new Error('Failed to trigger background check. Please try again next year.');
    }
  },

  /**
   * Retrieves information about the latest disk usage check.
   * @param {string} callername - The username of the caller.
   * @returns {Promise<Object>} A promise that resolves to an object with the following structure:
   *   {
   *     latest_check: {
   *       start_time: string,
   *       end_time: string,
   *       duration: number
   *     } | null,
   *     message: string | null
   *   }
   * @throws {Error} If the retrieval fails or if the caller is not authorized.
   */
  getLatestDiskCheck: async (callername) => {
    try {
      const response = await journalApi.get('/latest_disk_check', {
        headers: {
          'callername': callername
        }
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          throw new Error('You are not authorized to retrieve disk check information.');
        }
      }
      throw new Error('Failed to retrieve latest disk check information. Please try again later.');
    }
  },

  //  =======================  Daily entry operations ==============================================================

  addEntry: async (username, entryDate, content) => {
    try {
      const response = await journalApi.post(`/entries/${username}`, {
        entry_date: entryDate,
        content,
      });
      return { success: true, data: response.data };
    } catch (error) {
      if (error.response) {
        // Handle specific error status codes
        if (error.response.status === 413) {
          return {
            success: false,
            error: 'Entry content is too large. Try to reduce text, not the images.',
            details: error.response.data.detail
          };
        }
        // Handle other API errors
        return {
          success: false,
          error: error.response.data.detail || 'An error occurred while saving the entry',
          status: error.response.status
        };
      }
      // Handle network errors or other issues
      return {
        success: false,
        error: 'Unable to connect to the server',
        details: error.message
      };
    }
  },

  getEntry: async (username, date) => {
    try {
      const formattedDate = formatDate(date);
      const response = await journalApi.get(`/entries/${username}/${formattedDate}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching journal entry:', error);
      throw error;
    }
  },

  searchEntries: async (username, query, startDate = null, endDate = null) => {
    try {
      let url = `/search/${username}?query=${encodeURIComponent(query)}`;

      if (startDate) {
        url += `&start_date=${formatDate(startDate)}`;
      }
      if (endDate) {
        url += `&end_date=${formatDate(endDate)}`;
      }

      const response = await journalApi.get(url);
      return response.data;
    } catch (error) {
      console.error('Error searching journal entries:', error);
      throw error;
    }
  },

  /*
    Category -> Sidebar Operations
    ==============================
  */

  getAllSidebarCategoriesAndContents: async (username) => {
    const response = await journalApi.get(`/sidebar/${username}`);
    return response.data;  // Make sure we're returning response.data, not just response
  },
  getSidebarContent: async (username, categoryName) => {
    const response = await journalApi.get(`/sidebar/${username}/${categoryName}`);
    return response.data;
  },
  addSidebarContent: async (username, content, tag) => {
    const response = await journalApi.post(`/sidebar/${username}`, content, tag);
    return response.data;
  },
  updateSidebarContent: async (username, oldCategory, oldContent, updateData) => {
    const payload = {
      username: username,
      oldCategory: oldCategory,
      oldContent: oldContent,
      category: updateData.category,
      content: updateData.content,
      tag: updateData.tag
    };
    try {
      const response = await journalApi.post('/sidebar/update', payload);
      return response.data;
    } catch (error) {
      console.error('Error updating sidebar content', { error: error.message });
      throw error;
    }
  },
  deleteSidebarContentRow: async (username, categoryName, content) => {
    const encodedCategory = encodeURIComponent(categoryName);
    // Use base64 encoding for content to safely handle URLs
    const encodedContent = btoa(content);
    const response = await journalApi.delete(
      `/sidebar/${username}/${encodedCategory}/${encodedContent}`
    );
    return response.data;
  },

  deleteSidebarCategory: async (username, categoryName) => {
    const encodedCategoryName = encodeURIComponent(categoryName);
    const response = await journalApi.delete(`/sidebar/${username}/${encodedCategoryName}`);
    return response.data;
  },

  // ======================= Image handling ==========================

  /**
   * Upload an image for a specific user and date.
   *
   * @param {string} username - The username of the user.
   * @param {string} date - The date associated with the image.
   * @param {File} file - The image file to be uploaded.
   * @returns {Promise<Object>} - A promise that resolves to the response data.
   * @throws {Error} - Throws an error if the upload fails.
   */
  async uploadImage(username, date, file) {
    const formattedDate = formatDate(date);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("username", username);
    formData.append("date", formattedDate);

    // Log the FormData to verify its contents
    for (let [key, value] of formData.entries()) {
      // console.log(`${key}:`, value);
    }

    try {
      const response = await journalApi.post("/upload_image/", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error response:', error.response);
      console.error('Error request:', error.request);
      console.error('Error message:', error.message);
      if (error.response && error.response.status === 413) {
        throw new Error(`Image file size is too large. Maximum file size is 5MB. Server response: ${error.response.data.detail}`);
      }
      throw new Error(`Error uploading image: ${error.response?.data?.detail || error.message}`);
    }
  },

  /**
   * Retrieve an image URL for a specific user, date, and filename.
   *
   * @param {string} username - The username of the user.
   * @param {string} date - The date associated with the image.
   * @param {string} filename - The filename of the image to be retrieved.
   * @returns {Promise<string>} - A promise that resolves to the image URL.
   * @throws {Error} - Throws an error if the retrieval fails.
   */
  async getImage(username, date, filename) {
    try {
      // console.log(`Fetching image: username=${username}, date=${date}, filename=${filename}`);
      const response = await journalApi.get("/get_image/", {
        params: {
          username: username,
          date: date,
          filename: filename,
        },
      });
      // console.log('Image URL received:', response.data.url);
      return response.data.url;
    } catch (error) {
      console.error('Error fetching image:', error.response ? error.response.data : error.message);
      throw new Error(`Error retrieving image: ${error.response?.data?.detail || error.message}`);
    }
  },

  /**
   * Delete an image for a specific user, date, and filename.
   *
   * @param {string} username - The username of the user.
   * @param {string} date - The date associated with the image.
   * @param {string} filename - The filename of the image to be deleted.
   * @returns {Promise<Object>} - A promise that resolves to the response data.
   * @throws {Error} - Throws an error if the deletion fails.
   */
  async deleteImage(username, date, filename) {
    // const formattedDate = formatDate(date);
    try {
      const response = await journalApi.delete("/delete_image/", {
        params: {
          username: username,
          // date: formattedDate,
          date: date,
          filename: filename,
        },
      });
      // console.log('Delete successful:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error deleting image:', error.response?.data || error.message);
      throw new Error(`Error deleting image: ${error.response?.data?.detail || error.message}`);
    }
  },
  /**
   * Start a scan for undone tasks and poll for results.
   * @param {string} username - The username of the user
   * @param {Date|string} startDate - Start date 
   * @param {Date|string} endDate - End date
   * @returns {Promise<Array<{date: string, has_undone_tasks: boolean}>>}
   */
  scanUndoneEntries: async (username, startDate, endDate) => {
    try {
      // Start the scan
      const { data: { scan_id } } = await journalApi.post(
        `/entries/${username}/scan-undone/start`,
        {
          start_date: startDate,
          end_date: endDate
        }
      );

      // Poll for results
      let attempts = 0;
      const maxAttempts = 30; // 30 seconds maximum

      while (attempts < maxAttempts) {
        const { data: status } = await journalApi.get(
          `/entries/${username}/scan-undone/status/${scan_id}`
        );

        if (status.status === 'completed') {
          return status.results;
        }

        if (status.status === 'error') {
          throw new Error(status.error || 'Scan failed');
        }

        // Wait 1 second before next attempt
        await new Promise(resolve => setTimeout(resolve, 1000));
        attempts++;
      }

      throw new Error('Scan timed out');
    } catch (error) {
      console.error('Error scanning for undone entries:', error);
      if (error.response?.data?.detail) {
        throw new Error(error.response.data.detail);
      }
      throw error;
    }
  },
  /**
    * Gets the current disk usage information for a specific user.
    * @param {string} username - The username to get disk usage information for.
    * @returns {Promise<{
  *   max_disk_usage: number,    // Maximum allowed disk usage in bytes (e.g., 209715200 for 200MB)
  *   user_current_usage: number // Current disk usage in bytes (e.g., 52428800 for 50MB)
  * }>} A promise that resolves to the user's disk usage information
  * @throws {Error} If the request fails or if the user is not found.
  * @example
  * try {
  *   const usage = await NEW_JournalApiService.getDiskUsage('johndoe');
  *   // Returns: { max_disk_usage: 209715200, user_current_usage: 52428800 }
  * } catch (error) {
  *   console.error(error.message);
  * }
  */
  getDiskUsage: async (username) => {
    try {
      const response = await journalApi.get(`/user/disk-usage/${username}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            throw new Error('Authentication required. Please log in again.');
          case 404:
            throw new Error('User quota information not found.');
          default:
            throw new Error(`Failed to get disk usage: ${error.response.data.detail || 'Unknown error'}`);
        }
      }
      throw new Error('Network error. Please check your connection and try again.');
    }
  },

}

export default NEW_JournalApiService;
