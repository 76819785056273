// speechApiService.js

// Define base path constant
const BASE_PATH = "/ai/api/v1/speech";

// Logging utility
const log = (message, data = null) => {
  const timestamp = new Date().toISOString();
  if (data) {
    console.log(`[${timestamp}] SPEECH: ${message}`, data);
  } else {
    console.log(`[${timestamp}] SPEECH: ${message}`);
  }
};

// WebSocket URL constructor
const getWebSocketUrl = (clientId) => {
  const wsProtocol = window.location.protocol === "https:" ? "wss:" : "ws:";
  const baseUrl = `${wsProtocol}//${window.location.host}${BASE_PATH}`;

  // Extract token from cookie
  let token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("access_token="));

  if (token) {
    token = token.split("=")[1];
    if (token.startsWith('"Bearer ')) {
      token = token.substring(8, token.length - 1);
    } else if (token.startsWith("Bearer ")) {
      token = token.substring(7);
    }
  }

  return `${baseUrl}/ws/${clientId}?token=${token || ""}`;
};

// Simplified AudioWorklet code as a string
const audioWorkletCode = `
class AudioProcessor extends AudioWorkletProcessor {
  constructor(options) {
    super();
    
    const processorOptions = options.processorOptions || {};
    
    // Setup configuration
    this.targetSampleRate = processorOptions.targetSampleRate || 16000;
    this.originalSampleRate = processorOptions.originalSampleRate || 44100;
    this.needsResampling = this.originalSampleRate !== this.targetSampleRate;
    
    // Use a larger chunk size to capture more context for better recognition
    this.chunkSize = processorOptions.chunkSize || 32000; // ~2 seconds of audio at 16kHz
    if (this.chunkSize % 2 !== 0) {
      this.chunkSize += 2;
    }
    
    // Lower noise threshold to capture more speech
    this.noiseThreshold = processorOptions.noiseThreshold || 0.005; // Lower default threshold (0-1 scale)
    this.noiseGateHoldFrames = processorOptions.noiseGateHoldFrames || 10; // Extended hold time
    this.noiseGateCounter = 0; 
    this.isAboveThreshold = false;
    
    this.buffer = new Float32Array(0);
    this.chunksSent = 0;
    this.bufferThreshold = Math.floor(this.chunkSize * 0.25); // 25% of chunk for processing
    
    // Track signal levels
    this.maxLevel = 0;
    this.sumSquares = 0;
    this.sampleCount = 0;
    
    this.port.postMessage({
      type: "init",
      status: "initialized",
      config: {
        targetSampleRate: this.targetSampleRate,
        originalSampleRate: this.originalSampleRate,
        chunkSize: this.chunkSize,
        noiseThreshold: this.noiseThreshold,
        noiseGateHoldFrames: this.noiseGateHoldFrames,
        needsResampling: this.needsResampling
      },
    });
  }

  // Update the audio processor code in speechApiService.js
// Find the process method in the AudioProcessor class and modify it:

process(inputs, outputs, parameters) {
  const input = inputs[0];
  if (!input || !input[0] || input[0].length === 0) return true;

  const audioData = input[0];
  
  // Calculate frame RMS for noise gate
  let frameRMS = 0;
  for (let i = 0; i < audioData.length; i++) {
    frameRMS += audioData[i] * audioData[i];
  }
  frameRMS = Math.sqrt(frameRMS / audioData.length);
  
  // Noise gate logic
  let aboveThreshold = frameRMS > this.noiseThreshold;
  
  // If above threshold or still in hold period
  if (aboveThreshold) {
    this.isAboveThreshold = true;
    this.noiseGateCounter = this.noiseGateHoldFrames; // Reset hold counter
  } else if (this.noiseGateCounter > 0) {
    this.noiseGateCounter--; // Decrement hold counter
  } else {
    this.isAboveThreshold = false;
  }
  
  // Send level updates occasionally for UI feedback
  if (this.sampleCount % 1000 === 0) {
    this.port.postMessage({
      type: "levels",
      rms: frameRMS,
      isAboveThreshold: this.isAboveThreshold
    });
  }
  
  // IMPORTANT: Always add to buffer to ensure we don't miss speech beginnings
  // Update audio level metrics
  for (let i = 0; i < audioData.length; i++) {
    const abs = Math.abs(audioData[i]);
    if (abs > this.maxLevel) this.maxLevel = abs;
    this.sumSquares += audioData[i] * audioData[i];
    this.sampleCount++;
  }
  
  // Create new buffer with combined data
  const newBuffer = new Float32Array(this.buffer.length + audioData.length);
  newBuffer.set(this.buffer);
  newBuffer.set(audioData, this.buffer.length);
  this.buffer = newBuffer;
  
  // Only process when we have enough data AND 
  // either we're above threshold or we have accumulated a lot of data
  const maxBufferSize = this.chunkSize * 2; // Prevent buffer from growing too large
  
  if ((this.buffer.length >= this.bufferThreshold && this.isAboveThreshold) || 
      this.buffer.length >= maxBufferSize) {
    
    // Get chunk of data up to chunkSize
    const dataSize = Math.min(this.buffer.length, this.chunkSize);
    const dataToProcess = this.buffer.slice(0, dataSize);
    this.buffer = this.buffer.slice(dataSize);

    // Resample only if needed
    const processedData = this.needsResampling ? this.resample(dataToProcess) : dataToProcess;
    
    // Apply gentle normalization to improve recognition quality
    const normalizedData = this.normalizeAudio(processedData);

    // Calculate RMS of this chunk
    let chunkRMS = 0;
    for (let i = 0; i < normalizedData.length; i++) {
      chunkRMS += normalizedData[i] * normalizedData[i];
    }
    chunkRMS = Math.sqrt(chunkRMS / normalizedData.length);
    
    // CRITICAL: Only skip frames that are BOTH below threshold AND have very low RMS
    const skipThreshold = 0.003;
    let shouldSend = true;
    
    // Skip ONLY if BOTH conditions are met:
    // 1. Not above threshold (definitely not speech)
    // 2. Very low RMS (definitely just background noise)
    if (!this.isAboveThreshold && chunkRMS < skipThreshold) {
      shouldSend = false;
      
      // Still increment counter for consistent numbering
      this.chunksSent++;
    }
    
    if (shouldSend) {
      // Convert to 16-bit PCM
      const pcmData = new Int16Array(normalizedData.length);
      
      for (let i = 0; i < normalizedData.length; i++) {
        // Scale and clamp to 16-bit range
        pcmData[i] = Math.max(
          -32768,
          Math.min(32767, Math.round(normalizedData[i] * 32767))
        );
      }

      // Calculate audio metrics for this chunk
      const rms = Math.sqrt(this.sumSquares / this.sampleCount);
      
      this.chunksSent++;
      
      // Send PCM directly to main thread
      this.port.postMessage(
        {
          type: "audio",
          audioBuffer: pcmData.buffer,
          meta: {
            format: "pcm16",
            sampleRate: this.targetSampleRate,
            channels: 1,
            chunkNumber: this.chunksSent,
            maxLevel: this.maxLevel,
            rms: rms,
            isAboveThreshold: this.isAboveThreshold
          },
        },
        [pcmData.buffer] // Transfer ownership
      );
    }
    
    // Reset metrics
    this.maxLevel = 0;
    this.sumSquares = 0;
    this.sampleCount = 0;
  }

  return true;
}

  // Only resample if needed (when source and target rates differ)
  resample(audioData) {
    if (!this.needsResampling) {
      return audioData;
    }

    // Resampling ratio (e.g., 44.1kHz to 16kHz = 2.75625)
    const ratio = this.originalSampleRate / this.targetSampleRate;
    const resampledLength = Math.floor(audioData.length / ratio);
    // Ensure resampled length is even for 16-bit samples
    const alignedLength = resampledLength % 2 === 0 ? resampledLength : resampledLength + 1;
    const result = new Float32Array(alignedLength);

    // Higher quality resampling with linear interpolation
    for (let i = 0; i < alignedLength; i++) {
      const exactIdx = i * ratio;
      const idx1 = Math.floor(exactIdx);
      const idx2 = Math.min(idx1 + 1, audioData.length - 1);
      const frac = exactIdx - idx1;
      
      // Linear interpolation between the two nearest samples
      result[i] = audioData[idx1] * (1 - frac) + audioData[idx2] * frac;
    }
    
    return result;
  }
  
  // Normalize audio for better recognition - more gentle approach
  normalizeAudio(audioData) {
    // Find peak value
    let peak = 0;
    for (let i = 0; i < audioData.length; i++) {
      const abs = Math.abs(audioData[i]);
      if (abs > peak) peak = abs;
    }
    
    // If peak is very low, apply minimum gain to avoid silence
    const minGain = 0.1;
    if (peak < minGain) peak = minGain;
    
    // Calculate gain to reach target level (0.8 = 80% of full scale)
    // This prevents clipping while keeping good volume
    const targetLevel = 0.8;
    const gain = peak > 0 ? targetLevel / peak : 1.0;
    
    // Apply gain
    const normalized = new Float32Array(audioData.length);
    for (let i = 0; i < audioData.length; i++) {
      normalized[i] = audioData[i] * gain;
    }
    
    return normalized;
  }
}

registerProcessor("speech-audio-processor", AudioProcessor);
`;

// Create and download the AudioWorklet file dynamically
const createAudioProcessorWorklet = () => {
  const blob = new Blob([audioWorkletCode], { type: "application/javascript" });
  return URL.createObjectURL(blob);
};

// Manage the dynamic worklet URL
let workletUrl = null;

const speechApi = {
  createSpeechConnection: (clientId, callbacks = {}, options = {}) => {
    const wsUrl = getWebSocketUrl(clientId);
    // log(`Connecting to WebSocket: ${wsUrl}`);

    let ws = null; // Initialize ws here
    try {
      ws = new WebSocket(wsUrl);
    } catch (e) {
      log("WebSocket creation failed:", e);
      if (callbacks.onError)
        callbacks.onError("WebSocket connection failed to initialize.");
      return null; // Cannot proceed
    }

    let audioContext = null;
    let audioWorklet = null;
    let mediaStreamSource = null; // To hold the source node
    let audioStream = null; // Holds the stream being used (shared or local)
    let isProcessing = false;
    let lastAudioStats = { rms: 0, maxLevel: 0, isAboveThreshold: false };

    // *** Flag to track if the stream is externally provided (shared) ***
    let cleanupRequiresStopTrack = false; // Default: Assume we acquire locally

    // Default config + merge options
    const config = {
      deviceId: null,
      targetSampleRate: 16000,
      noiseThreshold: 0.012,
      noiseGateHoldFrames: 10,
      stream: null, // <<< Expect potential shared stream via options
      ...options,
    };

    // log("Speech connection config:", {
    //   deviceId: config.deviceId,
    //   targetSampleRate: config.targetSampleRate,
    //   hasStreamOption: !!config.stream,
    // });

    // Create worklet URL if needed (Unchanged)
    if (!workletUrl) {
      workletUrl = createAudioProcessorWorklet();
      // log("Created audio processor worklet URL");
    }

    // --- Cleanup Function (modified to use flag) ---
    const cleanupResources = () => {
      // log(
      //   `Cleaning up resources (Stop Tracks Flag: ${cleanupRequiresStopTrack})`
      // );
      isProcessing = false;

      if (audioWorklet) {
        try {
          audioWorklet.port.onmessage = null; // Remove listener
          audioWorklet.disconnect();
          // log("Disconnected AudioWorklet");
        } catch (e) {
          log("Error disconnecting audioWorklet:", e.message);
        }
        audioWorklet = null;
      }

      // Disconnect the source node AFTER worklet
      if (mediaStreamSource) {
        try {
          mediaStreamSource.disconnect();
          // log("Disconnected MediaStreamSource");
        } catch (e) {
          log("Error disconnecting mediaStreamSource:", e.message);
        }
        mediaStreamSource = null;
      }

      if (audioContext && audioContext.state !== "closed") {
        try {
          audioContext.close();
        } catch (e) {
          log("Error closing audioContext:", e.message);
        }
        audioContext = null;
      }

      // *** Conditional Track Stopping ***
      if (audioStream) {
        if (cleanupRequiresStopTrack) {
          // <<< Only stop if we acquired it
          try {
            log("Stopping tracks of locally acquired stream.");
            audioStream.getTracks().forEach((track) => {
              if (track.readyState === "live") {
                track.stop();
                log("Stopped audio track:", track.label || track.id);
              }
            });
          } catch (e) {
            log("Error stopping audio tracks:", e.message);
          }
        } else {
          log("Not stopping tracks of shared stream.");
        }
        audioStream = null; // Always clear the reference
      }

      if (
        ws &&
        (ws.readyState === WebSocket.OPEN ||
          ws.readyState === WebSocket.CONNECTING)
      ) {
        try {
          ws.close(1000, "Cleanup completed");
          // log("Closed WebSocket connection");
        } catch (e) {
          log("Error closing WebSocket:", e.message);
        }
      }
      ws = null; // Clear WebSocket reference
    };

    // --- WebSocket Open Handler (modified for stream handling) ---
    const handleOpen = async () => {
      // log("WebSocket connection opened.");
      if (callbacks.onOpen) callbacks.onOpen();

      // Reset flag before attempting stream setup
      cleanupRequiresStopTrack = false;

      try {
        // --- Determine which stream to use ---
        // console.log(
        //   ">>> speechApi: handleOpen entered. Checking config.stream:",
        //   config.stream
        // );
        if (
          config.stream &&
          config.stream instanceof MediaStream &&
          config.stream.active
        ) {
          // console.log(
          //   ">>> speechApi: Using provided shared MediaStream inside handleOpen."
          // );
          audioStream = config.stream;
          cleanupRequiresStopTrack = false; // Set flag: DO NOT stop tracks on cleanup
          const track = audioStream.getAudioTracks()[0];
          if (!track)
            throw new Error("Shared stream is missing an audio track.");
          // log("Shared stream track:", track.label || track.id);
          const settings = track.getSettings();
          // log("Shared stream track settings:", settings);
          // Use the sample rate from the shared stream's track settings
          config.actualSampleRate = settings.sampleRate || 44100;
        } else {
          // console.log(">>> speechApi: Acquiring new stream inside handleOpen.");
          const constraints = {
            audio: {
              deviceId: config.deviceId
                ? { exact: config.deviceId }
                : undefined,
              sampleRate: config.targetSampleRate, // Still prefer target rate
              channelCount: 1,
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: true,
            },
          };
          audioStream = await navigator.mediaDevices.getUserMedia(constraints);
          cleanupRequiresStopTrack = true; // Set flag: DO stop tracks on cleanup
          const track = audioStream.getAudioTracks()[0];
          // log("Acquired local microphone access:", track.label || track.id);
          const settings = track.getSettings();
          // log("Actual local track settings:", settings);
          // Use the actual sample rate we got
          config.actualSampleRate = settings.sampleRate || 44100;
        }
        // console.log(">>> speechApi: Determined stream to use:", audioStream); // Log the stream chosen
        // console.log(
        //   ">>> speechApi: cleanupRequiresStopTrack set to:",
        //   cleanupRequiresStopTrack
        // );

        // --- Setup AudioContext and Worklet ---
        // log(
        //   `Creating AudioContext with sample rate: ${config.actualSampleRate}`
        // );
        audioContext = new AudioContext({
          sampleRate: config.actualSampleRate,
          latencyHint: "interactive",
        });
        if (audioContext.state === "suspended") await audioContext.resume();
        // log(
        //   `>>> speechApi: AudioContext resumed. New state: ${audioContext.state}`
        // );

        // Add the audio processor worklet module
        try {
          await audioContext.audioWorklet.addModule(workletUrl);
          // log("Added audio processor worklet module");
        } catch (workletError) {
          log("Retrying worklet load after error:", workletError);
          // Fallback: Fetch and create a new URL might be needed in some strict environments
          if (workletUrl) URL.revokeObjectURL(workletUrl); // Clean up old URL if retrying
          workletUrl = createAudioProcessorWorklet();
          // log("Created new worklet URL for retry.");
          await audioContext.audioWorklet.addModule(workletUrl);
          // log("Added audio processor worklet module via fallback method");
        }

        // Create MediaStreamSource from the selected stream
        // log(
        //   `>>> speechApi: Creating MediaStreamSource from stream:`,
        //   audioStream
        // );
        mediaStreamSource = audioContext.createMediaStreamSource(audioStream);
        // log(`>>> speechApi: MediaStreamSource created:`, mediaStreamSource);

        // Create AudioWorkletNode
        audioWorklet = new AudioWorkletNode(
          audioContext,
          "speech-audio-processor",
          {
            processorOptions: {
              targetSampleRate: config.targetSampleRate,
              originalSampleRate: audioContext.sampleRate, // Use context's rate
              chunkSize: 32000,
              noiseThreshold: config.noiseThreshold,
              noiseGateHoldFrames: config.noiseGateHoldFrames,
            },
          }
        );
        // log("Created AudioWorkletNode.");

        // Handle messages from worklet
        audioWorklet.port.onmessage = (event) => {
          if (!isProcessing || !ws || ws.readyState !== WebSocket.OPEN) {
            // console.log("Worklet message ignored (not processing or WS not open)");
            return;
          }

          if (event.data.type === "audio") {
            // Update audio stats
            if (event.data.meta) {
              lastAudioStats = {
                /* ... update stats ... */ rms: event.data.meta.rms || 0,
                maxLevel: event.data.meta.maxLevel || 0,
                isAboveThreshold: event.data.meta.isAboveThreshold || false,
              };
              // Optional logging (throttled)
              // if (event.data.meta.chunkNumber % 5 === 0) log(/* ... log chunk ... */);
            }
            // Send audio buffer
            ws.send(event.data.audioBuffer);
          } else if (event.data.type === "init") {
            // log("Audio processor initialized:", event.data.config);
          } else if (event.data.type === "levels") {
            if (callbacks.onAudioLevel)
              callbacks.onAudioLevel({
                rms: event.data.rms,
                isAboveThreshold: event.data.isAboveThreshold,
              });
          } else if (event.data.type === "debug") {
            log(event.data.message);
          }
        };
        // log("Worklet message handler attached.");

        // Connect the audio processing graph
        // log(`>>> speechApi: Connecting MediaStreamSource to AudioWorkletNode.`);
        mediaStreamSource.connect(audioWorklet);
        // log(
        //   `>>> speechApi: Connection complete. Audio graph should be active.`
        // );
        // Do NOT connect worklet to destination unless debugging audio output
        // audioWorklet.connect(audioContext.destination);
        // log("Connected audio graph: Source -> Worklet");

        isProcessing = true;
        // log("Audio processing started.");

        // Start audio level monitoring if callback provided
        if (callbacks.onAudioLevel) {
          // Use interval or requestAnimationFrame for level updates
          // ... (keep existing level interval logic if desired) ...
        }
      } catch (error) {
        log(
          `Error during setup after WebSocket open: ${error.name}: ${error.message}`,
          error.stack
        );
        log(
          `>>> speechApi: ERROR during audio graph setup: ${error.message}`,
          error
        );
        if (callbacks.onError)
          callbacks.onError(`Setup failed: ${error.message}`);
        // Cleanup will use the correct state of cleanupRequiresStopTrack
        cleanupResources();
      }
    };

    // --- WebSocket Event Handlers ---
    ws.onopen = handleOpen;

    ws.onmessage = (event) => {
      // (Unchanged)
      try {
        // const data = JSON.parse(event.data); // Parsing might happen in parseSpeechResult
        // log("Received message:", event.data); // Log raw data if needed
        if (callbacks.onMessage) callbacks.onMessage(event.data);
      } catch (e) {
        log("Error parsing message:", e.message);
      }
    };

    ws.onclose = (event) => {
      // Calls cleanup which uses the flag
      // log(
      //   `WebSocket closed: Code ${event.code}, Reason: "${event.reason}", Clean: ${event.wasClean}`
      // );
      cleanupResources(); // Cleanup uses the closure flag correctly
      if (callbacks.onClose)
        callbacks.onClose(event.code, event.reason, event.wasClean);
    };

    ws.onerror = (event) => {
      // Calls cleanup which uses the flag
      log("WebSocket error:", event.type || "Unknown error");
      cleanupResources(); // Cleanup uses the closure flag correctly
      if (callbacks.onError) callbacks.onError("Connection error");
    };

    // --- Return Control Object ---
    return {
      isRecording: () => isProcessing,
      getAudioStats: () => ({ ...lastAudioStats }),
      stop: () => {
        log("Stop requested.");
        cleanupResources(); // Cleanup uses the flag
        return true;
      },
      forceStop: () => {
        log("Force stop requested.");
        cleanupResources(); // Cleanup uses the flag
        return true;
      },
    };
  }, // End of createSpeechConnection

  getAudioDevices: async () => {
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return { error: "Media devices not supported" };
      }

      const tempStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Get actual track settings for debug info
      const trackSettings = tempStream.getAudioTracks()[0].getSettings();
      log("Default microphone settings:", trackSettings);

      const devices = await navigator.mediaDevices.enumerateDevices();
      tempStream.getTracks().forEach((track) => track.stop());

      const audioInputs = devices.filter(
        (device) => device.kind === "audioinput"
      );
      log(`Found ${audioInputs.length} audio devices`);

      return {
        devices: audioInputs.map((device) => ({
          deviceId: device.deviceId,
          label: device.label || `Microphone ${device.deviceId.slice(0, 5)}...`,
          groupId: device.groupId,
        })),
        defaultDeviceSettings: trackSettings,
      };
    } catch (err) {
      log(`Error listing devices: ${err.message}`);
      return { error: err.message };
    }
  },

  parseSpeechResult: (jsonString) => {
    try {
      if (!jsonString || jsonString.trim() === "") {
        return { type: "error", error: "Empty response" };
      }

      const result = JSON.parse(jsonString);
      // log("Parsing result:", result);

      if (result.type === "final" && result.text !== undefined) {
        return {
          type: "final",
          text: result.text,
          confidence: result.confidence || 0,
        };
      }

      if (result.type === "partial" && result.text !== undefined) {
        return {
          type: "partial",
          text: result.text,
        };
      }

      if (result.status !== undefined) {
        return {
          type: "status",
          status: result.status,
          final: result.final || false,
          message: result.message || "",
        };
      }

      if (result.error !== undefined) {
        return {
          type: "error",
          error: result.error,
          message: result.message || "",
        };
      }

      return { type: "unknown", raw: result };
    } catch (e) {
      log("Error parsing result:", e.message);
      return { type: "error", error: e.message, raw: jsonString };
    }
  },

  // Function to create the audio processor worklet file if needed
  createWorkletFile: () => {
    if (!workletUrl) {
      workletUrl = createAudioProcessorWorklet();
    }

    return workletUrl;
  },

  // Add a debug function to help diagnose audio issues
  debugAudioCapture: async () => {
    try {
      // Try to get microphone with direct 16kHz
      const constraints16k = {
        audio: {
          sampleRate: 16000,
          channelCount: 1,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      };

      log("Testing 16kHz capture...");
      try {
        const stream16k = await navigator.mediaDevices.getUserMedia(
          constraints16k
        );
        const track16k = stream16k.getAudioTracks()[0];
        const settings16k = track16k.getSettings();
        log("16kHz test successful. Track settings:", settings16k);
        stream16k.getTracks().forEach((track) => track.stop());
      } catch (e) {
        log("16kHz test failed:", e.message);
      }

      // Try with default settings
      const constraintsDefault = {
        audio: true,
      };

      log("Testing default audio capture...");
      const streamDefault = await navigator.mediaDevices.getUserMedia(
        constraintsDefault
      );
      const trackDefault = streamDefault.getAudioTracks()[0];
      const settingsDefault = trackDefault.getSettings();
      log("Default audio test successful. Track settings:", settingsDefault);

      // Create audio context to check browser capabilities
      const audioCtx = new AudioContext();
      log("Audio context sample rate:", audioCtx.sampleRate);
      audioCtx.close();

      streamDefault.getTracks().forEach((track) => track.stop());

      return {
        defaultSettings: settingsDefault,
        contextSampleRate: audioCtx.sampleRate,
        browser: navigator.userAgent,
      };
    } catch (e) {
      log("Debug audio error:", e.message);
      return { error: e.message };
    }
  },
};

export default speechApi;
