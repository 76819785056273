import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';

const STORAGE_KEY = 'defaultMicrophoneId';

const AudioDeviceSelector = ({ onDeviceSelected, initialDeviceId }) => {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentDevice, setCurrentDevice] = useState(null);
  
  const loadDevices = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // First, check what device is currently being used
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const track = stream.getAudioTracks()[0];
      const settings = track.getSettings();
      console.log('Current audio track:', track.label, settings);
      setCurrentDevice(settings.deviceId);
      
      // Stop the temporary stream
      stream.getTracks().forEach(track => track.stop());
      
      // Now enumerate all devices
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputs = devices.filter(device => device.kind === 'audioinput');
      
      console.log('Available audio devices:', audioInputs);
      
      if (audioInputs.length > 0) {
        setDevices(audioInputs);
        
        // Try to select device in this order:
        // 1. Passed in initialDeviceId
        // 2. Stored device ID from localStorage
        // 3. Current active device
        // 4. First available device
        const storedDeviceId = localStorage.getItem(STORAGE_KEY);
        const deviceToSelect = 
          audioInputs.find(d => d.deviceId === initialDeviceId)?.deviceId ||
          audioInputs.find(d => d.deviceId === storedDeviceId)?.deviceId ||
          settings.deviceId ||
          audioInputs[0].deviceId;
        
        setSelectedDevice(deviceToSelect);
      } else {
        setError('No audio input devices found');
      }
    } catch (err) {
      console.error('Error loading audio devices:', err);
      setError(err.message || 'Failed to load audio devices');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDevices();
    
    // Set up device change listener
    navigator.mediaDevices.addEventListener('devicechange', loadDevices);
    
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', loadDevices);
    };
  }, [initialDeviceId]); // Added initialDeviceId to dependencies

  const handleSelectDevice = async () => {
    if (!selectedDevice) return;
    
    try {
      console.log('Attempting to select device:', selectedDevice);
      
      // Test the selected device first with specific constraints
      const constraints = {
        audio: {
          deviceId: { exact: selectedDevice },
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      };
      
      console.log('Using constraints:', JSON.stringify(constraints, null, 2));
      
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      
      // Log the actual device being used
      const track = stream.getAudioTracks()[0];
      const settings = track.getSettings();
      console.log('Stream received. Track settings:', settings);
      console.log('Actual device selected:', track.label);
      
      // Stop the test stream
      stream.getTracks().forEach(track => track.stop());
      
      // Verify the selected device matches what we requested
      if (settings.deviceId !== selectedDevice) {
        console.warn('Warning: Selected device does not match requested device');
        console.log('Requested:', selectedDevice);
        console.log('Received:', settings.deviceId);
      }
      
      // Store the selection in localStorage
      localStorage.setItem(STORAGE_KEY, selectedDevice);
      
      // Update current device state
      setCurrentDevice(selectedDevice);
      
      // Only update if we successfully accessed the device
      if (onDeviceSelected) {
        const device = devices.find(d => d.deviceId === selectedDevice);
        if (!device) {
          console.warn('Selected device not found in device list');
        }
        onDeviceSelected(selectedDevice, device?.label);
      }
    } catch (err) {
      console.error('Error testing selected device:', err);
      setError(`Failed to access selected device: ${err.message}`);
    }
  };

  // If initial device changes, update selected device
  useEffect(() => {
    if (initialDeviceId && devices.some(d => d.deviceId === initialDeviceId)) {
      setSelectedDevice(initialDeviceId);
    }
  }, [initialDeviceId, devices]);

  return (
    <Card className="mb-4">
      <Card.Header>
        <h5 className="mb-0">Select Microphone</h5>
      </Card.Header>
      <Card.Body>
        {error && (
          <Alert variant="danger">{error}</Alert>
        )}
        
        {loading ? (
          <div className="text-center py-3">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p className="mt-2">Detecting audio devices...</p>
          </div>
        ) : (
          <div>
            {currentDevice && (
              <div className="text-muted mb-2">
                Current device: {devices.find(d => d.deviceId === currentDevice)?.label || 'Unknown'}
              </div>
            )}
            
            <Form.Group className="mb-3">
              <Form.Label>Available Microphones:</Form.Label>
              <Form.Select 
                value={selectedDevice || ''}
                onChange={(e) => setSelectedDevice(e.target.value)}
                disabled={loading}
              >
                {devices.map(device => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || `Microphone ${device.deviceId.slice(0, 5)}...`}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            
            <div className="d-flex justify-content-between">
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={loadDevices}
                disabled={loading}
              >
                Refresh Devices
              </Button>
              
              <Button
                variant="primary"
                onClick={handleSelectDevice}
                disabled={!selectedDevice || loading}
              >
                Use Selected Device
              </Button>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default AudioDeviceSelector;