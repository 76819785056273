// AssistantWrapper.jsx
import React, { useState, useEffect, useRef, useCallback } from "react"; // Added useCallback
import { Button, Card } from "react-bootstrap";
import { BotMessageSquare, X } from "lucide-react";
import Assistant from "./Assistant"; // Assuming Assistant is in the same directory

// No need for forwardRef here anymore if we use a callback prop for the inner ref
const AssistantWrapper = ({
  handleDateClick,
  reloadContent,
  isSidebarOpen,
  sidebarWidth,
  // wakeWordEnabled, // No longer needed here
  showTaskPlexer,
  setShowTaskPlexer,
  onMicrophoneReleased,
  sharedMediaStream, // <<< New Prop: The shared stream
  forwardedAssistantRef, // <<< New Prop: Callback to set Assistant ref in AppFooter
}) => {
  const [cardHeight, setCardHeight] = useState(500);
  const [isDragging, setIsDragging] = useState(false);
  const [initialY, setInitialY] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const assistantRef = useRef(null); // Ref for the <Assistant> component instance

  // --- Pass the Assistant instance ref up to AppFooter ---
  // Use useCallback to ensure the ref function identity is stable if needed,
  // although useEffect dependency handles changes correctly anyway.
  const setAssistantRef = useCallback(
    (node) => {
      assistantRef.current = node; // Keep internal ref if needed
      if (forwardedAssistantRef) {
        forwardedAssistantRef(node); // Call the callback passed from AppFooter
      }
    },
    [forwardedAssistantRef]
  );

  // Log visibility changes
  useEffect(() => {
    if (showTaskPlexer) {
      console.log("TaskPlexer became visible");
    }
  }, [showTaskPlexer]);

  // Stop listening when TaskPlexer is closed manually via X button
  useEffect(() => {
    // Check assistantRef.current exists and has the isListening property/method
    if (!showTaskPlexer && assistantRef.current?.isListening) {
      console.log("TaskPlexer was closed, attempting to stop listening");
      assistantRef.current.stopListening();
    }
    // Note: If closed because Assistant finished normally, stopListening
    // would likely have been called already by Assistant itself.
  }, [showTaskPlexer]);

  const handleMouseDown = (e) => {
    setInitialY(e.clientY);
    setInitialHeight(cardHeight);
    setIsDragging(true);
    e.preventDefault();
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        const deltaY = initialY - e.clientY;
        const newHeight = Math.max(300, Math.min(800, initialHeight + deltaY));
        setCardHeight(newHeight);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, initialHeight, initialY]);

  return (
    <>
      <Button
        variant="outline-secondary"
        className="d-flex align-items-center border-0"
        style={{ height: "20px" }}
        onClick={() => setShowTaskPlexer(!showTaskPlexer)}
        aria-label="TaskPlexer"
      >
        <BotMessageSquare />
        <span style={{ fontSize: "0.8rem" }}></span>
      </Button>

      {/* TaskPlexer Card */}
      {showTaskPlexer && (
        <div
          className="position-fixed bottom-0 end-0 mb-5 me-4"
          style={{ zIndex: 1050 }}
        >
          {" "}
          {/* Ensure high z-index */}
          <Card
            style={{
              width: isSidebarOpen
                ? `calc(100vw - ${sidebarWidth + 30}px)`
                : "calc(100vw - 30px)", // Adjusted width slightly
              maxWidth: "72rem",
              height: `${cardHeight}px`,
            }}
            className="shadow-lg" // Use shadow-lg for more emphasis
          >
            {/* Resizer Handle */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: "6px" /* Increased height */,
                cursor: "ns-resize",
                backgroundColor: "transparent",
                zIndex: 1,
              }}
              onMouseDown={handleMouseDown}
              // Add visual indicator on hover if desired
            />
            {/* Card Header */}
            <Card.Header className="d-flex justify-content-between align-items-center py-2 pe-2 ps-3">
              {" "}
              {/* Adjusted padding */}
              <span className="fw-semibold text-primary">
                <BotMessageSquare size={18} className="me-2" />
                TaskPlexer
              </span>
              <Button
                variant="link"
                className="p-0 text-secondary lh-1" // Line height 1
                onClick={() => setShowTaskPlexer(false)}
                aria-label="Close TaskPlexer"
              >
                <X size={20} /> {/* Slightly larger X */}
              </Button>
            </Card.Header>
            {/* Card Body */}
            <Card.Body
              className="p-0 overflow-hidden" // No padding, let Assistant handle it
              style={{ height: `calc(${cardHeight}px - 45px)` }} // Adjust height based on header
            >
              {/* Render Assistant and pass necessary props */}
              <Assistant
                ref={setAssistantRef} // <<< Pass the callback ref setter
                sharedMediaStream={sharedMediaStream} // <<< Pass the shared stream
                handleDateClick={handleDateClick}
                reloadContent={reloadContent}
                onMicrophoneReleased={onMicrophoneReleased} // <<< Pass the release callback
                // Add any other props Assistant needs
              />
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default AssistantWrapper;
