import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useImperativeHandle,
} from "react";
import {
  BotMessageSquare,
  X,
  Loader,
  MicIcon,
  MicOff,
  Settings,
} from "lucide-react";
import { Button, Form, Card, Container, Row, Col } from "react-bootstrap";
import assistantApi from "../../../services/assistantApiService";
import speechApi from "../../../services/speechApiService";
import AudioDeviceSelector from "../../../components/AudioDeviceSelector";
import { useAuth } from "../../../contexts/AuthContext";
import ReactMarkdown from "react-markdown";

const STORAGE_KEY = "defaultMicrophoneId";

const Assistant = React.forwardRef(
  (
    { handleDateClick, reloadContent, onMicrophoneReleased, sharedMediaStream },
    ref
  ) => {
    // --- State ---
    const [messages, setMessages] = useState([]);
    const messageHistoryLimit = 4;
    const [inputMessage, setInputMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false); // For API calls
    const [isSpeechLoading, setIsSpeechLoading] = useState(false); // For starting speech recognition
    const [error, setError] = useState(null); // For API errors
    const [speechError, setSpeechError] = useState(null); // For Speech API errors
    const [isListening, setIsListening] = useState(false);
    const [transcript, setTranscript] = useState(""); // Final transcript from speech API
    const [partialTranscript, setPartialTranscript] = useState(""); // Partial transcript
    const [audioDeviceId, setAudioDeviceId] = useState(() =>
      localStorage.getItem(STORAGE_KEY)
    );
    const [showDeviceSelector, setShowDeviceSelector] = useState(false);

    // --- Refs ---
    const messagesEndRef = useRef(null);
    const websocketRef = useRef(null);
    const microphoneStreamRef = useRef(null); // Holds the stream being USED (shared or acquired)
    const isStreamSharedRef = useRef(false); // Tracks if the current stream is the shared one
    const isMountedRef = useRef(true); // Track mount status

    // --- Context ---
    const { user } = useAuth();

    // --- Imperative Handle: Expose methods to parent (AppFooter via AssistantWrapper) ---
    useImperativeHandle(ref, () => ({
      // Modified startListening to accept the stream
      startListening: (stream = null) => {
        // Default to null if no stream passed
        // console.log("Assistant: startListening called via ref.");
        // Pass the stream argument to the internal start function
        _startListening(stream);
      },
      stopListening: _stopListening, // Expose internal stop function
      isListening: isListening, // Expose current listening state
    }));

    // --- Lifecycle and Cleanup ---
    useEffect(() => {
      isMountedRef.current = true;
      console.log("Assistant Mounted");
      return () => {
        isMountedRef.current = false;
        console.log("Assistant Unmounted - cleaning up speech resources");
        // Ensure stopListening is called which handles cleanup
        _stopListening(true); // Pass flag indicating unmount cleanup
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty array: Run only on mount/unmount

    useEffect(() => {
      scrollToBottom();
    }, [messages]);

    // Update input field when final transcript arrives
    useEffect(() => {
      if (transcript) {
        setInputMessage(transcript);
        // Don't clear partial here, wait for explicit stop/new start
        setTranscript(""); // Clear transcript state once used
      }
    }, [transcript]);

    // --- Microphone Management ---

    // Modified Release Function: Knows about shared streams
    const releaseMicrophoneStream = useCallback(() => {
      if (!microphoneStreamRef.current) {
        // console.log("ReleaseMicrophoneStream: No stream to release.");
        return; // Nothing to do
      }

      // console.log("Assistant: Releasing microphone stream...");
      const wasStreamShared = isStreamSharedRef.current;

      try {
        // Only stop tracks if the stream was NOT shared (i.e., we acquired it)
        if (!wasStreamShared) {
          // console.log("Assistant: Stopping tracks of locally acquired stream.");
          microphoneStreamRef.current.getTracks().forEach((track) => {
            if (track.readyState === "live") {
              track.stop();
              console.log(
                `Assistant: Track ${track.kind} (${track.label}) stopped.`
              );
            }
          });
        }
        // else {
        //   console.log("Assistant: Not stopping tracks of shared stream.");
        // }
      } catch (error) {
        console.error("Assistant: Error stopping microphone tracks:", error);
      } finally {
        // Always clear the reference and the shared flag
        microphoneStreamRef.current = null;
        isStreamSharedRef.current = false;

        // Notify parent AFTER clearing refs, only if mounted
        if (onMicrophoneReleased && isMountedRef.current) {
          console.log(
            "Assistant: Notifying parent that microphone control is released."
          );
          onMicrophoneReleased();
        } else {
          console.log(
            "Assistant: Not notifying parent (unmounted or no callback)."
          );
        }
      }
    }, [onMicrophoneReleased]); // Dependency

    // --- Speech Recognition Logic ---

    // Internal Start Listening Function (accepts stream)
    const _startListening = useCallback(
      async (stream = null) => {
        console.log(
          ">>> Assistant: _startListening invoked. Provided stream:",
          stream
        ); // Log stream again
        if (isListening || isSpeechLoading) {
          console.log(
            "Assistant: Already listening or starting, ignoring request."
          );
          return;
        }
        if (websocketRef.current) {
          console.warn(
            "Assistant: WebSocket ref exists but not listening? Forcing cleanup."
          );
          websocketRef.current.forceStop(); // Ensure old one is gone
          websocketRef.current = null;
        }

        // Ensure any previous microphone reference is cleared (release won't harm if null)
        releaseMicrophoneStream();

        console.log("Assistant: Starting speech recognition process...");
        setIsSpeechLoading(true); // Indicate loading state
        setSpeechError(null);
        setInputMessage(""); // Clear input field
        setPartialTranscript(""); // Clear partial transcript

        let streamToUse = null;
        let deviceIdToUse = audioDeviceId; // Use state deviceId by default

        try {
          // 1. Determine Stream and Device ID
          if (stream) {
            // console.log("Assistant: Using provided shared MediaStream.");
            streamToUse = stream;
            isStreamSharedRef.current = true;
            // If using shared stream, ignore selected device ID for getUserMedia
            deviceIdToUse = null; // Don't try to get specific device from shared stream
          } else {
            console.log(
              "Assistant: No shared stream provided, attempting to acquire new one."
            );
            isStreamSharedRef.current = false;

            // Validate selected device ID if acquiring locally
            if (audioDeviceId) {
              const devices = await navigator.mediaDevices.enumerateDevices();
              const audioInputDevices = devices.filter(
                (d) => d.kind === "audioinput"
              );
              if (
                !audioInputDevices.some((d) => d.deviceId === audioDeviceId)
              ) {
                console.warn(
                  `Assistant: Stored deviceId "${audioDeviceId}" is invalid. Clearing.`
                );
                setAudioDeviceId(null);
                localStorage.removeItem(STORAGE_KEY);
                deviceIdToUse = null; // Use default mic
              } else {
                deviceIdToUse = audioDeviceId; // Use validated device ID
              }
            } else {
              deviceIdToUse = null; // Use default mic
            }

            console.log(
              `Assistant: Attempting getUserMedia with deviceId: ${
                deviceIdToUse || "default"
              }`
            );
            streamToUse = await navigator.mediaDevices.getUserMedia({
              audio: deviceIdToUse
                ? { deviceId: { exact: deviceIdToUse } }
                : true,
              video: false,
            });
            console.log(
              `Assistant: Acquired local stream. Track: ${
                streamToUse.getAudioTracks()[0]?.label
              }`
            );
          }

          // Store the stream reference (shared or local)
          microphoneStreamRef.current = streamToUse;

          // 2. Setup WebSocket Connection
          const clientId = `${user.username}-${Date.now()}`;
          const callbacks = {
            onOpen: () => {
              if (!isMountedRef.current) return;
              console.log("Assistant: WebSocket opened.");
              setIsListening(true); // Set listening state ONCE open
              setIsSpeechLoading(false); // Stop loading indicator
              setSpeechError(null);
            },
            onMessage: (data) => {
              if (!isMountedRef.current) return;
              // console.log("Assistant: Message received from speech API"); // Can be verbose
              try {
                const result = speechApi.parseSpeechResult(
                  typeof data === "string" ? data : JSON.stringify(data)
                );
                if (result.type === "final" && result.text) {
                  console.log(
                    "Assistant: Received final transcript:",
                    result.text
                  );
                  // Update transcript state (useEffect handles input field)
                  setTranscript((prev) =>
                    prev ? `${prev} ${result.text}` : result.text
                  ); // Append if multiple finals arrive quickly
                  // Optionally stop listening on final? Depends on desired behavior.
                  // Let timeout or explicit stop handle it for now.
                } else if (result.type === "partial") {
                  setPartialTranscript(result.text || "");
                } else if (
                  result.type === "status" &&
                  result.status === "complete"
                ) {
                  console.log(
                    "Assistant: Received 'complete' status.",
                    result.reason
                  );
                  // Stop listening if server indicates completion (e.g., timeout)
                  _stopListening();
                } else if (result.type === "error") {
                  console.error(
                    "Assistant: Recognition error from server:",
                    result
                  );
                  setSpeechError(
                    `Recognition error: ${result.error || "Unknown"}`
                  );
                  _stopListening(); // Stop on server error
                }
              } catch (err) {
                console.error(
                  "Assistant: Error processing speech message:",
                  err
                );
                setSpeechError(`Processing error: ${err.message}`);
              }
            },
            onClose: (code, reason, wasNormalClose) => {
              console.log(
                `Assistant: WebSocket closed. Code: ${code}, Reason: "${reason}", Normal: ${wasNormalClose}`
              );
              if (!isMountedRef.current && code !== 1000) return; // Don't update state if unmounting or clean close

              // Update state only if the component is still mounted
              if (isMountedRef.current) {
                if (!wasNormalClose && code !== 1000) {
                  // 1000 is normal close
                  setSpeechError(
                    `Connection closed unexpectedly: ${reason || code}`
                  );
                }
                setIsListening(false);
                setIsSpeechLoading(false); // Ensure loading stops
                websocketRef.current = null;
                // Ensure microphone is released when WebSocket closes unexpectedly or normally
                releaseMicrophoneStream();
              } else {
                // If unmounted, ensure cleanup happened
                microphoneStreamRef.current = null;
                isStreamSharedRef.current = false;
                websocketRef.current = null;
              }
            },
            onError: (errorMessage) => {
              console.error("Assistant: WebSocket error:", errorMessage);
              if (!isMountedRef.current) return;
              setSpeechError(errorMessage || "Speech connection failed");
              setIsListening(false);
              setIsSpeechLoading(false);
              websocketRef.current = null;
              // Ensure microphone is released on WebSocket error
              releaseMicrophoneStream();
            },
          };

          // console.log("Assistant: Creating speech connection...");
          // Pass the stream explicitly to the service
          const connection = speechApi.createSpeechConnection(
            clientId,
            callbacks,
            {
              // Pass deviceId only if we acquired the stream locally for that device
              deviceId: !isStreamSharedRef.current ? deviceIdToUse : null,
              stream: microphoneStreamRef.current, // Pass the stream being used
            }
          );

          if (connection) {
            websocketRef.current = connection;
            // Listening state is set in onOpen callback
          } else {
            throw new Error("Failed to create speech connection instance.");
          }
        } catch (error) {
          console.error(
            "Assistant: Failed to start speech recognition:",
            error
          );
          if (isMountedRef.current) {
            setSpeechError(
              error.message || "Microphone access or connection failed"
            );
            setIsListening(false);
            setIsSpeechLoading(false);
            // Ensure cleanup happens on error
            releaseMicrophoneStream(); // Release if we acquired locally
            websocketRef.current = null;
            isStreamSharedRef.current = false; // Reset shared flag
            microphoneStreamRef.current = null; // Clear ref
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      [audioDeviceId, user?.username, releaseMicrophoneStream]
    ); // Dependencies

    // Internal Stop Listening Function
    const _stopListening = useCallback(
      (isUnmounting = false) => {
        if (!isListening && !websocketRef.current && !isSpeechLoading) {
          // console.log("Assistant: Stop called but not listening/loading.");
          // Still ensure mic release just in case state is inconsistent
          if (!isUnmounting) releaseMicrophoneStream();
          return;
        }
        console.log("Assistant: Stopping speech recognition...");

        if (websocketRef.current) {
          websocketRef.current.stop(); // Tell the service to stop sending audio
          websocketRef.current = null; // Clear ref immediately
        }

        // Update state immediately unless unmounting
        if (!isUnmounting && isMountedRef.current) {
          setIsListening(false);
          setIsSpeechLoading(false);
          setSpeechError(null); // Clear error on explicit stop
          // Don't clear partial transcript here, keep it visible until next listen
        }

        // Always release the microphone resource after stopping WS
        // Add small delay *only if not unmounting* to allow WS close message processing
        if (!isUnmounting) {
          setTimeout(() => {
            if (isMountedRef.current) releaseMicrophoneStream();
          }, 50); // Short delay
        } else {
          // If unmounting, release immediately
          releaseMicrophoneStream();
        }
      },
      [isListening, isSpeechLoading, releaseMicrophoneStream]
    ); // Dependencies

    // --- UI Handlers ---

    const formatMessageForApi = (messageContent) => {
      const recentMessages = messages.slice(-messageHistoryLimit * 2);
      let formattedMessage = "";

      if (recentMessages.length > 0) {
        formattedMessage += "Previous conversation:\n";
        recentMessages.forEach((msg) => {
          const role = msg.role === "user" ? "User" : "Assistant";
          formattedMessage += `${role}: ${msg.content}\n`;
        });
        formattedMessage += "\nCurrent message:\n";
      }

      formattedMessage += messageContent;

      return formattedMessage;
    };

    const handleSendMessage = async () => {
      if (!inputMessage.trim() || isLoading) return;

      try {
        setIsLoading(true);
        const currentMessage = inputMessage.trim();

        setMessages((prev) => [
          ...prev,
          {
            role: "user",
            content: currentMessage,
          },
        ]);
        setInputMessage("");

        const messageWithContext = formatMessageForApi(currentMessage);

        const data = await assistantApi.sendMessage(
          messageWithContext,
          user.username
        );

        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: data.response,
          },
        ]);

        if (data.response.includes("Journal Entry for")) {
          const dateMatch = data.response.match(
            /Journal Entry for (\d{4}-\d{2}-\d{2})/
          );
          if (dateMatch && dateMatch[1]) {
            const date = new Date(dateMatch[1]);
            handleDateClick(date);
            await reloadContent(date);
          }
        }
      } catch (error) {
        console.error(error);
        const errorMessage = error.message
          .split("\n")
          .filter((line) => !line.trim().startsWith("at "))
          .join("\n");

        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: errorMessage,
          },
        ]);
        setError("Failed to send message. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    };

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Function to release the microphone stream
    const handleDeviceSelected = (deviceId, deviceLabel) => {
      setAudioDeviceId(deviceId);
      localStorage.setItem(STORAGE_KEY, deviceId);
      setShowDeviceSelector(false);
      console.log(
        `Assistant: Selected audio device: ${deviceLabel} (${deviceId})`
      );

      // If listening, restart with the new device
      // Note: This will acquire a *new* stream, not use the shared one.
      // Maybe disable device selection if using shared stream? Or handle differently?
      if (isListening) {
        console.log("Assistant: Restarting listening with new device.");
        _stopListening();
        setTimeout(() => _startListening(), 100); // Start without stream arg to use selected device
      }
    };

    // Manual Toggle Button
    const toggleListening = () => {
      if (isListening || isSpeechLoading) {
        _stopListening();
      } else {
        // When manually toggled, *don't* pass the shared stream.
        // Let it acquire based on selected device.
        _startListening(); // Call without arguments
      }
    };

    return (
      <Container fluid className="d-flex flex-column h-100 p-0">
        <div className="flex-grow-1 overflow-auto p-3">
          {messages.map((message, index) => (
            <Row key={index} className="mb-3">
              <Col
                className={message.role === "user" ? "ms-auto" : ""}
                xs="auto"
                style={{ maxWidth: "80%" }}
              >
                <Card
                  className={`border-0 ${
                    message.role === "user"
                      ? "bg-primary bg-opacity-10"
                      : "bg-light"
                  }`}
                >
                  <Card.Body className="p-2">
                    <small className="fw-medium d-block mb-1">
                      {message.role === "user" ? "You" : "Assistant"}
                    </small>
                    <div className="markdown-content">
                      <ReactMarkdown
                        components={{
                          h1: ({ node, ...props }) => (
                            <h1 style={{ fontSize: "1.2rem" }} {...props} />
                          ),
                        }}
                      >
                        {message.content}
                      </ReactMarkdown>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
          <div ref={messagesEndRef} />

          {error && (
            <Row className="justify-content-center mb-3">
              <Col xs="auto">
                <div className="text-danger bg-danger bg-opacity-10 p-2 rounded">
                  {error}
                </div>
              </Col>
            </Row>
          )}

          {speechError && (
            <Row className="justify-content-center mb-3">
              <Col xs="auto">
                <div className="text-warning bg-warning bg-opacity-10 p-2 rounded">
                  {speechError}
                </div>
              </Col>
            </Row>
          )}
        </div>

        {/* Input Area */}
        <div className="border-top p-3 bg-light">
          <Row className="g-2 align-items-center">
            <Col>
              <Form.Control
                as="textarea"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder={
                  isListening
                    ? partialTranscript
                      ? `Listening... "${partialTranscript}"`
                      : "Listening..."
                    : isSpeechLoading
                    ? "Starting microphone..."
                    : "Type your message or press Mic..."
                }
                disabled={isLoading || isSpeechLoading} // Disable during API or speech loading
                rows={1}
                style={{ resize: "none", overflowY: "auto", minHeight: "38px" }}
                className={` ${isListening ? "border-primary shadow-sm" : ""}`}
              />
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              {/* Send Button */}
              <Button
                variant="primary"
                onClick={handleSendMessage}
                disabled={isLoading || isSpeechLoading || !inputMessage.trim()} // Also disable if speech loading
                style={{ minWidth: "70px" }} // Slightly smaller
                className="me-2"
              >
                {isLoading ? (
                  <Loader
                    className="spinner-border spinner-border-sm"
                    size={16}
                  />
                ) : (
                  "Send"
                )}
              </Button>

              {/* Mic Toggle Button */}
              <Button
                variant="outline-secondary"
                className={`p-0 d-flex align-items-center justify-content-center me-2 ${
                  isSpeechLoading ? "disabled" : ""
                }`}
                style={{ width: "38px", height: "38px", aspectRatio: "1 / 1" }}
                onClick={toggleListening}
                disabled={isSpeechLoading} // Disable while starting mic
                title={
                  isListening ? "Stop listening" : "Start speech recognition"
                }
              >
                {isListening ? (
                  <MicIcon size={20} className="text-danger" />
                ) : isSpeechLoading ? (
                  <Loader
                    size={18}
                    className="text-muted spinner-border spinner-border-sm"
                  />
                ) : (
                  <MicOff size={20} className="text-muted" />
                )}
              </Button>

              {/* Settings/Device Selector Toggle */}
              <Button
                variant="outline-secondary"
                className="p-0 d-flex align-items-center justify-content-center"
                style={{ width: "38px", height: "38px", aspectRatio: "1 / 1" }}
                onClick={() => setShowDeviceSelector((s) => !s)}
                title="Select microphone"
                // Disable device selection if using a shared stream?
                // disabled={isStreamSharedRef.current} // Optional: Prevent changing device when shared stream is active
              >
                <Settings size={18} className="text-muted" />
              </Button>
            </Col>
          </Row>
          {/* Device Selector Dropdown (simplified positioning) */}
          {showDeviceSelector && (
            <div className="mt-2">
              {" "}
              {/* Position below input row */}
              <AudioDeviceSelector
                onDeviceSelected={handleDeviceSelected}
                initialDeviceId={audioDeviceId}
              />
            </div>
          )}
        </div>
      </Container>
    );
  }
);
export default Assistant;
